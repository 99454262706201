import { Button, Modal } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginThunk } from '../../../../redux/reducers/authSlice';

export default function LoginNowModal({ username, password, onLoggedIn, ...props }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, isLogin } = useSelector((state) => state.auth);

  const login = () => dispatch(loginThunk({ username, password }));

  useEffect(() => {
    if (isLogin) onLoggedIn();
  }, [isLogin]);

  return (
    <Modal
      closable={false}
      maskClosable={false}
      footer={false}
      centered
      title={<div className="text-16 gray-900 fw-7">회원가입 성공</div>}
      {...props}>
      <div className="text-14 gray-700 ">
        바로 로그인하시려면 아래 <b>{`"로그인"`}</b> 버튼을 선택해 주세요
      </div>
      <div className="w-100 mt-4 d-flex">
        <Button block onClick={() => navigate('/login')} className="me-3" size="large">
          닫기
        </Button>
        <Button block type="primary" onClick={login} loading={loading} size="large">
          로그인
        </Button>
      </div>
    </Modal>
  );
}
