import apiClient from '../../services/axios';

export const createTransfer = async (transferData) => {
  try {
    const response = await apiClient.post('/transfer', transferData);
    if (response.status === 201) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error creating transfer:', error);
    return null;
  }
};
