import { Row, Col, Typography, Button, Divider } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router-dom';
import { BookIcon } from '../../../assets/icons/Icons';
import { Mail } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfoThunk, logoutThunk } from '../../../redux/reducers/authSlice';
import SendInactiveInquiry from './SendInactiveInquiry';
import { useEffect, useState } from 'react';
import { openBusinessIntroduce } from '../../../redux/reducers/layoutSlice';
import BusinessIntroduce from '../../../common/layout/BusinessIntroduce';

const { Text, Title } = Typography;

const SignInInactive = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openSendMessage, setOpenSendMessage] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchUserInfoThunk());
  }, []);

  const handleClickLogout = () => {
    dispatch(logoutThunk());
    navigate('/login');
  };

  return (
    <>
      <div
        className="successPage"
        style={{
          height: '100vh',
          width: '100%',
          position: 'relative'
        }}>
        <Content
          style={{
            minHeight: 380,
            backgroundColor: '#fff',
            padding: '96px 32px 32px 32px',
            fontWeight: 500
          }}>
          <Row>
            <Col span={24}>
              <Title level={3} style={{ color: '#4BBF9F', fontWeight: '700' }}>
              {userInfo?.full_name}님
              </Title>
              <Title level={3} style={{ fontWeight: '700', marginTop: '0px' }}>
                환영해요!
              </Title>
            </Col>
            <Col span={24} className="d-flex flex-column mt-3">
              <Text className="mb-2">{'가입이 완료되었습니다'}</Text>
              <Text className="mb-2">{'ACI 계정 승인까지 최대 1~3영업일 소요됩니다'}</Text>
              <Text className="mb-2">{'승인이 완료되면 이메일(ID)로 연락드리겠습니다'}</Text>
            </Col>
            <Col span={24}>
              <Button block type="primary" onClick={handleClickLogout} size="large" className="mt-3">
                로그아웃
              </Button>
            </Col>
          </Row>
        </Content>
        <div
          className="w-100 d-flex flex-column"
          style={{
            position: 'absolute',
            bottom: 0,
            padding: '15px'
          }}>
          <Divider>궁금한 점이 있으세요?</Divider>
          <Row className="w-100">
            <Col span={24}>
              <Button
                size="large"
                style={{
                  marginBottom: '16px',
                  fontSize: '14px'
                }}
                block
                onClick={() => {
                  dispatch(openBusinessIntroduce());
                }}
                className="d-flex">
                <div className="me-1">
                  <BookIcon />
                </div>
                이용가이드 바로가기
              </Button>
            </Col>
            <Col span={24}>
              <Button
                size="large"
                style={{
                  marginBottom: '16px',
                  fontSize: '14px'
                }}
                block
                onClick={() => setOpenSendMessage(true)}
                className="d-flex">
                <div className="me-1">
                  <Mail />
                </div>
                문의하기
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <SendInactiveInquiry isOpen={openSendMessage} onClose={() => setOpenSendMessage(false)} />
      <BusinessIntroduce />
    </>
  );
};

export default SignInInactive;
