import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { forEach } from 'lodash';
import { Button, Checkbox, Col, ConfigProvider, Form, Input, Layout, message, Modal, Row, Space, Spin, Tooltip, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HeaderFT2 from '../../../common/layout/HeaderFT2';
import api from '../../../services/axios';
import { loginThunk } from '../../../redux/reducers/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { regX_password, getOptionsValidPassword } from '../../../utility/utils';
import BankAsyncSelect from '../../features/common/components/BankAsyncSelect';
import UploadFileCustom from '../../../common/components/UploadFile';
import ACITermsDrawer from './ACITermsDrawer';
import { USER_ROLE } from '../../../common/utilities/constants';
import { Info } from 'react-feather';
import { useClickOutside } from '../../../common/hooks/useClickOutside';
import { ButtonWithTooltip } from '../../features/common/components/ButtonWithTooltip';
const { Title, Text } = Typography;

const SignUpPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, _] = useSearchParams();
  const [form] = Form.useForm();
  const { loading, isLogin } = useSelector((state) => state.auth);
  const [openModal, setOpenModal] = useState(false);
  const [openACITermsDrawer, setOpenACITermsDrawer] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [acieSeg, setAcieSeg] = useState(false);
  const role = searchParams.get('role') || USER_ROLE.FreeTCustomer;
  const [businessInfoVerified, setBusinessInfoVerified] = useState(null);
  const [aciAccountTooltipOpened, setAciAccountTooltipOpened] = useState(true);
  const aciAccountRef = useRef(null);

  useEffect(() => {
    if (isLogin) {
      setOpenModal(false);
      if (role === USER_ROLE.FreeTCustomer || acieSeg) {
        navigate(`/refund-request`);
      } else {
        navigate('/inactive');
      }
    }
  }, [isLogin]);

  const isValidForm = (values) => {
    const isValidProfile = values.full_name && values.username;
    const isValidPassword =
      values.password &&
      regX_password.test(values.password) &&
      values.confirm_password &&
      values.confirm_password === values.password;

    if (role === USER_ROLE.FreeTCustomer) {
      return isValidProfile && isValidPassword;
    }

    const isValidPhone = values.phone;

    const isValidBusinessInfo =
      values.business?.registration_number &&
      values.business?.name &&
      values.business?.representative_name &&
      values.business?.email &&
      values.business?.business_url &&
      values.business?.pictures?.length > 0;
    const isValidACIE = values.acie_agree && (acieSeg ? values.acie_id && values.acie_password : true);
    return isValidPhone && isValidBusinessInfo && isValidProfile && isValidPassword && isValidACIE;
  };

  const verifyBusiness = async (registration_number) => {
    try {
      setVerifyLoading(true);
      const { data } = await api.post('/business/verify', { registration_number });

      if (data.valid) {
        message.success('유효한 등록번호');
        setBusinessInfoVerified(true);
        return true;
      }

      message.error('잘못된 등록번호');
      setBusinessInfoVerified(false);
      return false;
    } catch (e) {
      message.error('잘못된 등록번호');
      setBusinessInfoVerified(false);
      return false;
    } finally {
      setVerifyLoading(false);
    }
  };

  const signUp = async (body) => {
    setSignUpLoading(true);
    // remove all empty fields
    forEach(body, (value, key) => {
      if (value === null || value === undefined || value === '') {
        delete body[key];
      }
    });
    try {
      await api.post('/auth/signup', body);
      setSignUpLoading(false);
      if (acieSeg) {
        setOpenModal(true);
      } else {
        handleSignIn();
      }
    } catch (error) {
      setSignUpLoading(false);
      if (error?.response?.data?.code === 'invalid_business_reg_no') {
        message.error('잘못된 등록번호');
      } else {
        message.error(error?.response?.data?.message || 'Sign up failed');
      }
    }
  };

  const handleSignUp = async (values) => {
    if (role === USER_ROLE.FreeTBusiness && !businessInfoVerified) {
      const verified = await verifyBusiness(values.business?.registration_number);
      if (!verified) return;
    }
    signUp({ ...values, role });
  };

  const handleSignIn = () => {
    const { username, password } = form.getFieldsValue();
    dispatch(
      loginThunk({
        username,
        password
      })
    );
  };

  const AciAccountTooltipIcon = () => (
    <Tooltip
      overlayStyle={{ maxWidth: '100%', width: '100%', padding: '0 24px' }}
      overlayInnerStyle={{
        padding: '12px 12px 12px 36px',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        backgroundColor: 'black',
      }}
      style={{ alignSelf: 'center' }}
      title={
        <ul style={{ margin: 0, padding: 0 }}>
          <li>
            프리티고는 현재 ACI worldwide 사와 물류 데이터를 연동하고 있습니다.
          </li>
          <li>
            부가세환급 신청서 작성과 동시에 운송장 생성 및 출고 연동까지 가능합니다.
          </li>
          <li>
            해당 물류시스템에 가입되어 있지 않다면, 신규 계약 진행이 필요합니다.
          </li>
        </ul>
      }>
      <Info fill='#98A2B3' color='white' />
    </Tooltip>)

  const WithAciAccountButton = () =>
    <ButtonWithTooltip
      tooltipOpen={acieSeg && aciAccountTooltipOpened}
      buttonTitle='있어요'
      tooltipContent={<>
        사용하시는 ACI 계정 및 비밀번호를 입력해 주세요
        <br />
        *물류 연동 용도 외에 사용하지 않습니다.
      </>
      }
      buttonProps={{
        type: acieSeg ? 'primary' : 'default',
        onClick: () => {
          setAcieSeg(true);
          setAciAccountTooltipOpened(true);
        }
      }}
    />

  const WithoutAciAccountButton = () =>
    <ButtonWithTooltip
      tooltipOpen={!acieSeg && aciAccountTooltipOpened}
      buttonTitle='없어요'
      tooltipContent={<>
        계정이 없는 경우 프리티고에서 ACI와 계약 후 아이디를 공유드립니다.
        <br />
        물류비용은 개인 회원 대비 약 3~40% 할인된 요율로 제공되며 회원가입 후 메일로 배송 요율이 안내됩니다.
      </>}
      buttonProps={{
        type: acieSeg ? 'default' : 'primary',
        onClick: () => {
          setAcieSeg(false);
          setAciAccountTooltipOpened(true);
        }
      }}
    />

  // close tooltip when click outside of the button group
  useClickOutside(() => setAciAccountTooltipOpened(false), aciAccountRef);

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: '#3431D6',
            colorError: '#FF0000',
          }
        }
      }}
    >
      <Layout style={{ backgroundColor: '#fff', paddingBottom: '10px' }}>
        <HeaderFT2
          title={
            <Title level={4} style={{ margin: 0 }}>
              회원가입
            </Title>
          }
          onNavigate={() => navigate('/login')}
          isShadow={false}
          titleCenter={true}
        />
        <Form layout="vertical" form={form} name="signIn" onFinish={handleSignUp}>
          <Space direction="vertical" style={{ backgroundColor: '#F2F4F7', width: '100%' }} size={12}>
            <Content style={{ backgroundColor: '#fff', padding: '24px' }}>
              {/* Full Name */}
              <Form.Item
                name="full_name"
                label={
                  <div className="text-14 gray-900 fw-5">
                    이름 <span style={{ color: 'red' }}>*</span>
                  </div>
                }
                rules={[{ required: true, message: '이름을 입력하십시오' }]}>
                <Input placeholder="이름 입력" size="large" />
              </Form.Item>
              {/* phone */}
              {role === USER_ROLE.FreeTBusiness && (
                <Form.Item
                  name="phone"
                  label={
                    <div className="text-14 gray-900 fw-5">
                      전화 번호 <span style={{ color: 'red' }}>*</span>
                    </div>
                  }
                  rules={[{ required: true, message: '전화번호를 입력하십시오' }]}
                >
                  <Input placeholder="-는 제외하고 입력해 주세요" size="large" />
                </Form.Item>
              )}
              {/* Username */}
              <Form.Item
                name="username"
                label={
                  <div className="text-14 gray-900 fw-5">
                    이메일 <span style={{ color: 'red' }}>*</span>
                  </div>
                }
                extra={
                  <div style={{ color: '#F63D68' }}>
                    * 관련 사항이 모두 메일로 연락되니 반드시 수신할 수 있는 메일을 기재해 주세요
                  </div>
                }
                rules={[
                  {
                    type: 'email',
                    message: '이메일 형식이 올바르지 않아요'
                  }
                ]}>
                <Input placeholder="아이디(이메일) 입력" size="large" />
              </Form.Item>
              {/* Password */}
              <Form.Item
                name="password"
                label={
                  <div className="text-14 gray-900 fw-5">
                    비밀번호 <span style={{ color: 'red' }}>*</span>
                  </div>
                }
                style={{ marginBottom: '8px' }}>
                <Input.Password placeholder="비밀번호 입력" size="large" />
              </Form.Item>
              <Form.Item shouldUpdate={(preValue, curValue) => preValue.password !== curValue.password}>
                {({ getFieldValue }) => {
                  const config = getOptionsValidPassword(getFieldValue('password'));
                  const item = config.map((item) => (
                    <div key={item.item} className="d-flex ms-1" style={{ color: item.isValid ? '#1570EF' : '#98A2B3' }}>
                      <CheckOutlined /> <div className="ms-1">{item.description}</div>
                    </div>
                  ));
                  return <div className="d-flex">{item}</div>;
                }}
              </Form.Item>
              {/* Confirm Password */}
              <Form.Item name="confirm_password" style={{ marginBottom: '8px' }}>
                <Input.Password placeholder="비밀번호 확인" size="large" />
              </Form.Item>
              <Form.Item shouldUpdate={(preValue, curValue) => preValue.confirm_password !== curValue.confirm_password}>
                {({ getFieldValue }) => {
                  const item = {
                    key: 4,
                    description: '비밀번호 일치',
                    isValid:
                      getFieldValue('confirm_password') && getFieldValue('password') == getFieldValue('confirm_password')
                  };
                  return (
                    <div key={item.item} className="d-flex" style={{ color: item.isValid ? '#1570EF' : '#98A2B3' }}>
                      {item.isValid ? <CheckOutlined /> : <CloseOutlined />}{' '}
                      <div className="ms-1">{item.description}</div>
                    </div>
                  );
                }}
              </Form.Item>
              {/* Site ID */}
              <Form.Item
                name="site_id"
                label={<div className="text-14 gray-900 fw-5">제휴사 및 추천인</div>}
              // extra={<div style={{ color: '#F63D68' }}>* 이전 사이트 내 적립금이 3일 내 반영됩니다</div>}
              >
                <Input placeholder="제휴사 및 추천인을 입력해 주세요" size="large" />
              </Form.Item>
            </Content>
            {/* Bank Account */}
            <Content style={{ backgroundColor: 'white', padding: '24px' }}>
              {/* Title */}
              <Title level={5} style={{ marginBottom: '24px' }}>
                은행 정보 입력 (환급용)
              </Title>
              <Text type="danger" style={{ marginBottom: '16px' }}>
                은행 정보는 가입 후 프로필 페이지에서도 입력할 수 있어요
              </Text>
              {/* Name */}
              <Form.Item name={['bank_account', 'name']} label={<div className="text-14 gray-900 fw-5">이름</div>}>
                <Input placeholder="이름 입력" size="large" />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  {/* Bank Select */}
                  <Form.Item name={['bank_account', 'bank_id']} label={<div className="text-14 gray-900 fw-5">은행</div>}>
                    <BankAsyncSelect label="은행" placeholder="은행" style={{ height: '40px', marginTop: '3px' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {/* Holder name */}
                  <Form.Item
                    name={['bank_account', 'holder_name']}
                    label={<div className="text-14 gray-900 fw-5">예금주</div>}>
                    <Input placeholder="예금주" size="large" />
                  </Form.Item>
                </Col>
              </Row>
              {/* Account Number */}
              <Form.Item
                name={['bank_account', 'account_number']}
                label={<div className="text-14 gray-900 fw-5">계좌 번호</div>}>
                <Input placeholder="‘-’ 없이 숫자만 입력해 주세요" size="large" />
              </Form.Item>
            </Content>
            {/* ---------- Business Info ---------- */}
            {role === USER_ROLE.FreeTBusiness && (
              <>
                <Content style={{ backgroundColor: 'white', padding: '24px' }}>
                  {/* Title */}
                  <Title level={5} style={{ marginBottom: '24px' }}>
                    사업자 정보 입력
                  </Title>
                  {/* Business Registration NO */}
                  <Spin spinning={verifyLoading}>
                    <Form.Item
                      label={
                        <div className="text-14 gray-900 fw-5">
                          사업자 등록 번호 <span style={{ color: 'red' }}>*</span>
                        </div>
                      }
                      style={{ marginBottom: 0 }}>
                      <Form.Item
                        name={['business', 'registration_number']}
                        validateTrigger="onBlur"
                        style={{ display: 'inline-block', width: 'calc(100% - 88px)' }}>
                        <Input
                          placeholder="숫자만 입력해 주세요"
                          size="large"
                          onChange={() => setBusinessInfoVerified(false)}
                        />
                      </Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        block
                        style={{
                          margin: '0 0 8px 8px',
                          display: 'inline-block',
                          width: '80px',
                          fontSize: '14px',
                        }}
                        onClick={() => verifyBusiness(form.getFieldValue(['business', 'registration_number']))}>
                        인증하기
                      </Button>
                    </Form.Item>
                  </Spin>
                  {/* name */}
                  <Form.Item
                    name={['business', 'name']}
                    label={
                      <div className="text-14 gray-900 fw-5">
                        상호명 <span style={{ color: 'red' }}>*</span>
                      </div>
                    }>
                    <Input placeholder="상호명" size="large" />
                  </Form.Item>
                  {/* representative_name */}
                  <Form.Item
                    name={['business', 'representative_name']}
                    label={
                      <div className="text-14 gray-900 fw-5">
                        대표자 이름 <span style={{ color: 'red' }}>*</span>
                      </div>
                    }>
                    <Input placeholder="대표자 이름" size="large" />
                  </Form.Item>
                  {/* pictures */}
                  <Form.Item
                    label={
                      <div className="text-14 gray-900 fw-5">
                        사업자 등록증 <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                    name={['business', 'pictures']}>
                    <UploadFileCustom
                      maxCount={5}
                      accept=".png,.jpg,.jpeg"
                      description="이미지 업로드"
                      upload_hint="JPG or PNG..."
                      feature_type="business_account"
                    />
                  </Form.Item>
                  {/* email */}
                  <Form.Item
                    name={['business', 'email']}
                    label={
                      <div className="text-14 gray-900 fw-5">
                        기업 이메일 <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                    rules={[
                      {
                        type: 'email',
                        message: '이메일 형식이 올바르지 않아요'
                      }
                    ]}>
                    <Input placeholder="연락용 이메일을 작성해 주세요" size="large" />
                  </Form.Item>
                  {/* postal_code */}
                  <Form.Item name={['business', 'postal_code']} label="우편 번호">
                    <Input placeholder="우편 번호" size="large" />
                  </Form.Item>
                  {/* address */}
                  <Form.Item
                    name={['business', 'address']}
                    label="사업장 주소"
                    extra={<div style={{ color: '#F63D68' }}>* 사업장 주소는 영문으로 입력해 주세요</div>}>
                    <Input placeholder="사업장 주소" size="large" />
                  </Form.Item>
                  {/* business_url */}
                  <Form.Item
                    name={['business', 'business_url']}
                    label={
                      <div className="text-14 gray-900 fw-5">
                        판매처 주소 URL <span style={{ color: 'red' }}>*</span>
                      </div>
                    }
                    rules={[
                      {
                        validator: async (rule, value) => {
                          if (!/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+(\/[^\s]*)?$/.test(value)) {
                            throw new Error('판매처 주소 URL 형식');
                          }
                        },
                        message: '판매처 주소 URL 형식입니다.'
                      }
                    ]}
                    validateTrigger="onChange">
                    <Input placeholder="판매처 주소 URL" size="large" />
                  </Form.Item>
                  {/* product_type */}
                  <Form.Item name={['business', 'product_type']} label="취급 제품 종류 (예 : 의류, 주방용품 등)">
                    <Input placeholder="의류, 주방용품, 명품 등" size="large" />
                  </Form.Item>
                </Content>
                {/* ACIE Section */}
                <Content style={{ backgroundColor: 'white', padding: '24px' }}>
                  <Title level={4} style={{ display: 'flex', gap: '8px' }}>
                    ACI 계정<AciAccountTooltipIcon />
                  </Title>
                  <Row gutter={8} className="my-3" ref={aciAccountRef}>
                    <Col span={12}>
                      <WithAciAccountButton />
                    </Col>
                    <Col span={12}>
                      <WithoutAciAccountButton />
                    </Col>
                  </Row>
                  {acieSeg ? (
                    // Have ACIE
                    <>
                      <Form.Item className="mt-2" name="acie_id" style={{ marginBottom: '8px' }} label="ACI ID">
                        <Input placeholder="ACI" size="large" />
                      </Form.Item>
                      <Form.Item name="acie_password" style={{ marginBottom: '8px' }} label="ACI 비밀번호">
                        <Input.Password placeholder="비밀번호" size="large" />
                      </Form.Item>
                      <Form.Item name="acie_agree" valuePropName="checked">
                        <Checkbox size="large">
                          <div className="text-14 gray-900 fw-5">
                            ACI 계정 제공 동의 <span style={{ color: 'red' }}>*</span>
                          </div>
                        </Checkbox>
                      </Form.Item>
                    </>
                  ) : (
                    // No ACIE
                    <>
                      <Row
                        onClick={() => {
                          setOpenACITermsDrawer(true);
                        }}>
                        <Col span={2}>
                          <Form.Item className="mt-2" name="acie_agree" valuePropName="checked">
                            <Checkbox size="large" disabled className="readonly-checkbox d-flex w-100" />
                          </Form.Item>
                        </Col>
                        <Col span={22} style={{ paddingTop: '12px' }}>
                          <div className="d-flex justify-content-between w-100 parent-w-100">
                            <div className="text-14 gray-900 fw-5">
                              ACI 계정 제공 동의 <span style={{ color: 'red' }}>*</span>
                            </div>
                            <Text underline>내용보기</Text>
                          </div>
                        </Col>
                      </Row>
                      <ACITermsDrawer
                        open={openACITermsDrawer}
                        onClose={() => setOpenACITermsDrawer(false)}
                        onClick={() => {
                          setOpenACITermsDrawer(false);
                          form.setFieldsValue({ acie_agree: true });
                        }}
                      />
                    </>
                  )}
                </Content>
              </>
            )}
          </Space>
          <Content style={{ backgroundColor: '#fff', padding: '0px 24px 16px 24px' }}>
            {/* Submit Button */}
            <Form.Item shouldUpdate>
              {({ getFieldsValue }) => (
                <Button
                  block
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={signUpLoading}
                  disabled={!isValidForm(getFieldsValue())}>
                  회원가입
                </Button>
              )}
            </Form.Item>
            <Text style={{ display: 'block', textAlign: 'center', marginTop: '40px' }}>
              계정이 있으신가요?{' '}
              <Text underline onClick={() => navigate('/login')} style={{ fontWeight: 700 }}>
                로그인
              </Text>
            </Text>
          </Content>
        </Form>
        <Modal
          closable={false}
          maskClosable={false}
          open={openModal}
          footer={false}
          centered
          title={<div className="text-16 gray-900 fw-7">회원가입 성공</div>}>
          <div className="text-14 gray-700 ">
            바로 로그인하시려면 아래 <b>{`"로그인"`}</b> 버튼을 선택해 주세요
          </div>
          <div className="w-100 mt-4 d-flex">
            <Button block onClick={() => navigate('/login')} className="me-3" size="large">
              닫기
            </Button>
            <Button block type="primary" onClick={handleSignIn} loading={loading} size="large">
              로그인
            </Button>
          </div>
        </Modal>
      </Layout>
    </ConfigProvider>
  );
};

export default SignUpPage;
