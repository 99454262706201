import { Button, Drawer, Input, message, Typography } from 'antd';
import React, { useState } from 'react';
import { X } from 'react-feather';
import { apiClientV2 } from '../../../services/axios';
const { Text } = Typography;

const SendInactiveInquiry = ({onClose, isOpen, ...props}) => {
  const [value, setValue] = useState();

  const handleSendInquiry = async () => {
    try {
      const res = await apiClientV2.post('/message/inquiry', {
        content: `(FreeT Business Inactive contact): \n ${value}`
      });
      if (res.status === 201) {
        message.success('Successfully');
        onClose();
      } else {
        message.error(res?.data?.message || 'Error');
      }
    } catch (error) {
      message.error(error?.response?.data?.message || 'Error');
    }
  };
  return (
    <Drawer
      title={false}
      placement={'bottom'}
      closable={false}
      onClose={onClose}
      open={isOpen}
      rootClassName="optiosDrawer"
      contentWrapperStyle={{
        heigt: '200px',
        // maxHeight: '420px',
        overflow: 'scroll'
      }}>
      <div className="d-flex justify-content-between">
        <p
          style={{
            fontSize: '16px',
            color: '#101828',
            fontWeight: '700'
          }}>
          문의
        </p>
        <X style={{ marginBottom: '20px', fontSize: '18px' }} onClick={onClose} />
      </div>
      <div className="d-flex flex-column">
        <Text className='my-2'>영업 시간</Text>
        <Text className='mb-2' style={{fontWeight: '700'}}>17:30 ~ 0:00 / 주말, 공휴일 휴무</Text>
        <Text className='mb-3'>이메일(ID)로 답변을 보내드릴게요!</Text>
        <Input.TextArea
          rows={4}
          placeholder={`자유롭게 작성해 주세요`}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button type="primary" block disabled={!value} size="large" className="mt-4" onClick={handleSendInquiry}>
          제출하기
        </Button>
      </div>
    </Drawer>
  );
};

export default SendInactiveInquiry;
