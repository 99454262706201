import { Input, Form, Space, Button, Spin, Card, Modal, Typography, Col, Select, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  DEFAULT_QUANTITY_DROPDOWN,
  DEFAULT_HS_CODE_DROPDOWN,
  NUMBER_KOREAN,
  VAT_RATE,
  REFUND_RATE,
  COUNTRIES
} from '../../../../common/utilities/constants';
import './productCard.style.scss';
import InputEuroCurrency from '../../../../common/components/InputNumber/InputEuroCurrency';
const { Text } = Typography;

const ProductInfo = ({ mode = 'edit', onValidate, country, invoiceIndex, form, setHelpInfo, refundRate }) => {
  const [loadings, setLoadings] = useState([]);
  const currency = COUNTRIES?.[country]?.currency || COUNTRIES.france.currency;

  useEffect(() => {
    // if country changed then update the vat rate
    const products = form.getFieldValue(['invoices', invoiceIndex, 'products']);
    if (products) {
      products.forEach((_, key) => {
        updateProductPrices(key);
      });
    }
  }, [country]);

  const handleRemove = (remove) => {
    Modal.confirm({
      title: '삭제 하시겠습니까?',
      onCancel: () => {},
      onOk: () => {
        remove();
      },
      centered: true,
      icon: null,
      cancelText: '닫기',
      okText: '삭제',
      okButtonProps: {
        danger: true,
        type: 'primary',
        style: {
          marginRight: '8%',
          width: '40%'
        }
      },
      cancelButtonProps: {
        style: {
          width: '40%'
        }
      }
    });
  };

  const disableDeleteButton = () => {
    const products = form.getFieldValue(['invoices', invoiceIndex, 'products']) || [];
    return products.length <= 1;
  };

  const getProductNameCard = (key) => {
    return NUMBER_KOREAN[key] || '';
  };

  const updateProductPrices = (key) => {
    const products = form.getFieldValue(['invoices', invoiceIndex, 'products']);
    const unitPriceInclVat = products[key].unit_price_incl_vat;
    const unitPrice = Number((unitPriceInclVat / (1 + (COUNTRIES?.[country]?.vat_rate || VAT_RATE))).toFixed(2));
    const vat = Number((unitPriceInclVat - unitPrice).toFixed(2));
    const quantity = products[key].quantity;
    const totalPrice = unitPrice * quantity;
    const totalPriceInclVat = Number((unitPriceInclVat * quantity).toFixed(2)); 
    // const unitPrice = products[key].unit_price;
    // const totalPriceInclVat = Number((totalPrice * (1 + VAT_RATE)).toFixed(2));
    // const vat = Number((totalPriceInclVat - totalPrice).toFixed(2));
    const estRefundAmount = Number((totalPrice * (refundRate ?? (COUNTRIES?.[country]?.refund_rate || REFUND_RATE))).toFixed(2));

    products[key] = {
      ...products[key],
      total_price: totalPrice,
      total_price_incl_vat: totalPriceInclVat,
      unit_price: unitPrice,
      vat,
      est_refund_amount: estRefundAmount
    };

    form.setFieldsValue({ products });
  };

  const shouldUpdatePrices = (preValue, curValue) =>
    // preValue.products?.total_price_incl_vat !== curValue.products?.total_price_incl_vat;
    preValue.products?.unit_price_incl_vat !== curValue.products?.unit_price_incl_vat;

  const openProductOrginHelp = () => {
    setHelpInfo({
      show: true,
      title: '제품 원산지',
      content: (
        <ul>
          <li>FTA 서류 발급을 위해서는 원산지 기재가 필요해요</li>
        </ul>
      )
    });
  };

  const openTotalPriceInclVatHelp = () => {
    setHelpInfo({
      show: true,
      title: `최종 결제 금액 (${currency?.label})`,
      content: (
        <ul>
          <li>인보이스에 기재된 TOTAL T.T.C 금액을 기재해 주세요</li>
        </ul>
      )
    });
  };

  const openVatHelp = () => {
    setHelpInfo({
      show: true,
      title: '부가세',
      content: (
        <ul>
          <li>
            제품 단가의{' '}
            <Text strong type="danger">
              20%
            </Text>
            가 부가세로 추가돼요
          </li>
          <li>
            프랑스 부가세{' '}
            <Text strong type="danger">
              20%
            </Text>
            중{' '}
            <Text strong type="danger">
              10%
            </Text>
            를 돌려받을 수 있어요
          </li>
        </ul>
      )
    });
  };

  const openTotalPriceHelp = () => {
    setHelpInfo({
      show: true,
      title: '제품 단가',
      content: (
        <ul>
          <li>최종 결제 금액에서 부가세를 제외한 금액</li>
        </ul>
      )
    });
  };

  const openEstRefundAmountHelp = () => {
    setHelpInfo({
      show: true,
      title: '환급 예상 금액',
      content: (
        <ul>
          <li>
            프랑스 부가세{' '}
            <Text strong type="danger">
              20%
            </Text>
            중{' '}
            <Text strong type="danger">
              10%
            </Text>
            를 돌려받을 수 있어요
          </li>
        </ul>
      )
    });
  };

  return (
    <>
      <Form.List name={[invoiceIndex, 'products']} noStyle>
        {(productFields, { add: addProduct, remove: removeProduct }) => (
          <div>
            {productFields.map((field) => (
              <Space className="product-info-space" key={field.key}>
                <Spin tip="Loading..." spinning={loadings[field.key] || false}>
                  <Card className="mb-3">
                    <div className="d-flex justify-content-between align-item-center mb-3">
                      <div style={{ fontSize: '18px', color: '#344054', fontWeight: '700' }}>
                        {getProductNameCard(field.name)} 번째 제품
                      </div>
                      {mode !== 'view' && field.name !== 0 && (
                        <Button
                          style={{ width: '120px' }}
                          icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
                          size="large"
                          onClick={() => handleRemove(() => removeProduct(field.name))}
                          disabled={disableDeleteButton()}
                          key={`deleteBtn${field.key}`}
                          block>
                          삭제
                        </Button>
                      )}
                    </div>
                    {/* Product URL */}
                    <Form.Item
                      key={`product_url${field.key}`}
                      name={[field.name, 'product_url']}
                      rules={[
                        {
                          validator: async (rule, value) => {
                            if (!/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+(\/[^\s]*)?$/.test(value)) {
                              throw new Error('잘못된 URL 형식');
                            }
                          },
                          message: '잘못된 URL 형식입니다.'
                        }
                      ]}
                      validateTrigger="onChange"
                      label={
                        <div className="text-14 gray-900 fw-5">
                          구매처 URL <span style={{ color: 'red' }}>*</span>
                        </div>
                      }>
                      <Input size="large" placeholder="구매처 링크(URL)를 입력해 주세요" />
                    </Form.Item>
                    {/* Product Name */}
                    <Form.Item
                      key={`product_name${field.key}`}
                      name={[field.name, 'product_name']}
                      rules={[
                        {
                          required: true,
                          message: '제품명을 입력해주세요'
                        }
                      ]}
                      validateDebounce={1000}
                      label={
                        <Col>
                          <Row>
                            <Space className="no-width-space">
                              <Text>
                                제품명 <span style={{ color: 'red' }}>*</span>
                              </Text>
                            </Space>
                          </Row>
                          <Row>
                            <div className="text-14 gray-900" style={{ color: '#FF0000' }}>
                            영문으로 기재해주세요
                            </div>
                          </Row>
                        </Col>
                      }>
                      <Input size="large" placeholder="제품명을 입력해 주세요" />
                    </Form.Item>
                    {/* Product brand */}
                    <Form.Item
                      key={`brand${field.key}`}
                      name={[field.name, 'brand']}
                      rules={[
                        {
                          required: true,
                          message: '브랜드을 입력해주세요'
                        }
                      ]}
                      validateDebounce={1000}
                      label={
                        <Col>
                          <Row>
                            <Space className="no-width-space">
                              <Text>
                                브랜드 <span style={{ color: 'red' }}>*</span>
                              </Text>
                            </Space>
                          </Row>
                          <Row>
                            <div className="text-14 gray-900" style={{ color: '#FF0000' }}>
                            영문으로 기재해주세요
                            </div>
                          </Row>
                        </Col>
                      }>
                      <Input size="large" placeholder="브랜드를 입력해 주세요" />
                    </Form.Item>
                    {/* Product HS Code */}
                    <Form.Item
                      key={`hs_code${field.key}`}
                      name={[field.name, 'hs_code']}
                      rules={[
                        {
                          required: true,
                          message: 'HS code을 입력해주세요'
                        }
                      ]}
                      validateDebounce={1000}
                      label={
                        <Col>
                          <Row>
                            <Space className="no-width-space">
                              <Text>
                                HS Code <span style={{ color: 'red' }}>*</span>
                              </Text>
                            </Space>
                          </Row>
                        </Col>
                      }>
                      <Select size="large" placeholder="HS code" options={DEFAULT_HS_CODE_DROPDOWN} />
                    </Form.Item>
                    <Row gutter={[10]}>
                      {/* Product Origin */}
                      <Col span={16}>
                        <Form.Item
                          key={`product_origin${field.key}`}
                          label={
                            <Space className="no-width-space">
                              <div className="text-14 gray-900 fw-5">제품 원산지</div>
                              <QuestionCircleOutlined onClick={openProductOrginHelp} />
                            </Space>
                          }
                          name={[field.name, 'product_origin']}>
                          <Input size="large" placeholder="원산지를 입력해 주세요" />
                        </Form.Item>
                      </Col>
                      {/* Quantity */}
                      <Col span={8}>
                        <Form.Item
                          key={`quantity${field.key}`}
                          label={
                            <div className="text-14 gray-900 fw-5">
                              제품 수량 <span style={{ color: 'red' }}>*</span>
                            </div>
                          }
                          name={[field.name, 'quantity']}>
                          <Select
                            size="large"
                            placeholder="개수"
                            options={DEFAULT_QUANTITY_DROPDOWN}
                            onSelect={() => updateProductPrices(field.name)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* Product Option */}
                    <Form.Item
                      key={`product_option${field.key}`}
                      name={[field.name, 'product_option']}
                      rules={[
                        {
                          required: true,
                          message: '옵션 (사이즈/색상)을 입력해 주세요'
                        }
                      ]}
                      label={
                        <Text>
                          {'옵션 (사이즈/색상)'} <span style={{ color: 'red' }}>*</span>
                        </Text>
                      }>
                      <Input size="large" placeholder="Size/Color" />
                    </Form.Item>
                    {/* Unit Price incl VAT */}
                    <Form.Item
                      key={`unit_price_incl_vat_${field.key}`}
                      label={
                        <Row>
                          <Col span={24}>
                            <Space className="no-width-space">
                              <div className="text-14 gray-900 fw-5">
                                제품 가격 <span style={{ color: 'red' }}>*</span>
                              </div>
                              <QuestionCircleOutlined onClick={openTotalPriceHelp} />
                            </Space>
                          </Col>
                          <Col>
                            <div className="text-14 gray-900" style={{ color: '#FF0000' }}>
                              부가세가 포함된 가격을 입력해 주세요
                            </div>
                          </Col>
                        </Row>
                      }
                      name={[field.name, 'unit_price_incl_vat']}
                      validateTrigger="onChange"
                      rules={[
                        {
                          required: true,
                          message: '제품 단가를 입력해 주세요'
                        }
                      ]}>
                      <InputEuroCurrency className="w-100" onChange={() => updateProductPrices(field.name)} prefix={currency?.symbol}/>
                    </Form.Item>
                    {/* Total incl vat */}
                    <Form.Item
                      key={`total_price_incl_vat_${field.key}`}
                      label={
                        <Space className="no-width-space">
                          <div className="text-14 gray-900 fw-5">총 금액 (TOTAL HT)</div>
                          <QuestionCircleOutlined onClick={openTotalPriceInclVatHelp} />
                        </Space>
                      }
                      name={[field.name, 'total_price_incl_vat']}
                      shouldUpdate={shouldUpdatePrices}>
                      <InputEuroCurrency disabled className="w-100" prefix={currency?.symbol}/>
                    </Form.Item>
                    {/* Unit Price */}
                    <Form.Item
                      key={`unit_price_${field.key}`}
                      label={
                        <Row>
                          <Col span={24}>
                            <Space className="no-width-space">
                              <div className="text-14 gray-900 fw-5">제품 단가 ({currency?.label})</div>
                              <QuestionCircleOutlined onClick={openTotalPriceHelp} />
                            </Space>
                          </Col>
                        </Row>
                      }
                      name={[field.name, 'unit_price']}>
                      <InputEuroCurrency disabled className="w-100" prefix={currency?.symbol}/>
                    </Form.Item>
                    {/* VAT */}
                    <Form.Item
                      key={`vat_${field.key}`}
                      label={
                        <Space className="no-width-space">
                          <div className="text-14 gray-900 fw-5">부가세 (TVA {(COUNTRIES?.[country]?.vat_rate || VAT_RATE) * 100}%)</div>
                          <QuestionCircleOutlined onClick={openVatHelp} />
                        </Space>
                      }
                      name={[field.name, 'vat']}
                      shouldUpdate={shouldUpdatePrices}>
                      <InputEuroCurrency disabled className="w-100" prefix={currency?.symbol}/>
                    </Form.Item>
                    {/* Refund amount */}
                    <Form.Item
                      key={`est_refund_amount_${field.key}`}
                      label={
                        <Space className="no-width-space">
                          <div className="text-14 gray-900 fw-5">환급 예상 금액 ({currency?.label})</div>
                          <QuestionCircleOutlined onClick={openEstRefundAmountHelp} />
                        </Space>
                      }
                      name={[field.name, 'est_refund_amount']}
                      shouldUpdate={shouldUpdatePrices}>
                      <InputEuroCurrency disabled className="w-100" prefix={currency?.symbol}/>
                    </Form.Item>
                    {/* Memo */}
                    <Form.Item
                      key={`product_memo_${field.key}`}
                      label={<div className="text-14 gray-900 fw-5">메모</div>}
                      name={[field.name, 'memo']}>
                      <TextArea rows={3} placeholder="메모를 작성해 주세요" />
                    </Form.Item>
                  </Card>
                </Spin>
              </Space>
            ))}
            {mode !== 'view' && (
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  return (
                    <Button
                      block
                      className="ms-1"
                      type="primary"
                      onClick={() =>
                        addProduct({
                          product_url: '',
                          product_name: '',
                          product_origin: '',
                          product_option: '',
                          quantity: 1,
                          total_price: 0,
                          unit_price: 0,
                          vat: 0,
                          est_refund_amount: 0,
                          total_price_incl_vat: 0,
                          memo: null
                        })
                      }
                      // disabled={!submittable}
                      disabled={!onValidate(getFieldValue(['invoices', invoiceIndex, 'products']))}>
                      제품 추가 (최대 10개)
                    </Button>
                  );
                }}
              </Form.Item>
            )}
          </div>
        )}
      </Form.List>
    </>
  );
};

export default ProductInfo;
