import { Button, Col, Drawer, Row, Tooltip, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { USER_ROLE } from '../../../common/utilities/constants';
import { Info } from 'react-feather';

const { Title, Text, Paragraph } = Typography;

const SignUpOptionDrawer = ({ onClose, ...props }) => {
  const navigate = useNavigate();

  const BusinessTooltipIcon = () => (
    <Tooltip
      placement="topRight"
      overlayStyle={{ maxWidth: '300px' }}
      overlayInnerStyle={{
        padding: '12px 12px 12px 36px',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        backgroundColor: 'black',
      }}
      title={
        <ul style={{ margin: 0, padding: 0 }}>
          <li>
            월 이용료 99,000원이 부과됩니다.
          </li>
          <li>
            개인회원 해외배송료 대비 약 40% 할인된 요금으로 배송대행 이용 가능
          </li>
          <li>
            기타 맞춤형 포장 서비스 협의 가능
          </li>
          <li>
            기타 비즈니스 제안 가능
          </li>
        </ul>
      }>
      <Info fill='#98A2B3' color='white' onClick={(e) => e.stopPropagation()} />
    </Tooltip>)

  return (
    <Drawer
      title={<Title level={4}>유형을 선택해 주세요</Title>}
      placement="bottom"
      onClose={onClose}
      contentWrapperStyle={{ height: 'auto' }}
      style={{ borderRadius: '12px 12px 0px 0px' }}
      headerStyle={{ border: 'none' }}
      bodyStyle={{ padding: '0px 16px 41px 16px' }}
      {...props}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Button block type="default" size="large" onClick={() => navigate('/sign-up')} style={{ textAlign: 'left' }}>
            일반 고객
          </Button>
        </Col>
        <Col span={24}>
          <Button
            block
            type="default"
            size="large"
            onClick={() => navigate(`/sign-up?role=${USER_ROLE.FreeTBusiness}`)}
            style={{ textAlign: 'left', height: 'auto', display: 'flex', justifyContent: 'space-between' }}
          >
            사업자
            <BusinessTooltipIcon />
          </Button>
        </Col>
      </Row>
    </Drawer>
  );
};

export default SignUpOptionDrawer;
