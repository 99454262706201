import React from 'react';
import _ from 'lodash';
import { Button, Checkbox, Col, Divider, Form, Image, Input, Row, Space, Typography } from 'antd';
import { formatCurrency } from '../../../../utility/utils';
import { COUNTRIES } from '../../../../common/utilities/constants';
import './totalForm.style.scss';
import UploadFileCustom from '../../../../common/components/UploadFile';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

const TotalForm = ({ mode = 'edit', form, setHelpInfo, country, handleCoupon }) => {
  const [totalEstRefund, setTotalEstRefund] = React.useState(false);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [couponAmount, setCouponAmount] = React.useState(0);

  // if totalAmount is 0, unable to check the checkbox with useEffect
  React.useEffect(() => {
    if (mode !== 'view' && totalAmount <= 0) {
      form.setFieldValue('fta_doc_issuance', false);
    }
  }, [totalAmount]);

  React.useEffect(() => {
    getFinalEstRefund(form.getFieldValue);
  }, []);

  const currency = COUNTRIES?.[country]?.currency || COUNTRIES.france.currency;
  const fees = { fta_doc_issuance: 7 };

  const getTotalEstRefund = (getFieldValue) => {
    const invoices = getFieldValue('invoices') || [];
    let total = 0;
    invoices.forEach((invoice) => {
      const products = invoice?.products || [];
      const totalPod = _.sumBy(products, 'est_refund_amount') || 0;
      total += totalPod;
    });
    setTotalEstRefund(total);
    return total;
  };

  const getServiceFee = (getFieldValue) => {
    const fee = Object.keys(fees).reduce((acc, cur) => {
      return acc + (getFieldValue(cur) ? fees[cur] : 0);
    }, 0);
    return fee || 0;
  };

  const getDiscount = (getFieldValue) => {
    const invoices = getFieldValue('invoices') || [];
    const total = _.sumBy(invoices, 'discount') || 0;
    return total;
  };

  const getFinalEstRefund = (getFieldValue) => {
    const estRefund = getTotalEstRefund(getFieldValue);
    const serviceFee = getServiceFee(getFieldValue);
    const discount = getDiscount(getFieldValue);
    const couponAmount = getFieldValue('coupon_amount') || 0;
    setCouponAmount(couponAmount);
    const final = estRefund - serviceFee - discount + couponAmount;
    setTotalAmount(final);
    return final;
  };

  const openAdditionalServiceHelp = () => {
    setHelpInfo({
      show: true,
      title: '부가서비스',
      content: (
        <ul>
          <li>
            제조국을 확인하여 캡처한 후 <strong>구매 인보이스에 업로드</strong>해야 적용됩니다.
            <br />
            (오프라인 구매 건이라면, 상품 내부 택의 제조국을 촬영해서 업로드 해주세요)
          </li>
          <li>
            제품이 EU에서 생산된 경우, 관세 면제를 위한 서류 발급이 가능하며, 이를 위해 <strong>원산지 택 사진 또는 구매 사이트에서 확인된 제조국 정보를 등록</strong>해야 합니다.
          </li>
          <li>
            <Text type='danger'>제조 국가가 확인되지 않으면 적용이 불가합니다.</Text>
          </li>
        </ul>
      )
    });
  };

  const openCertificateImageHelp = () => {
    setHelpInfo({
      show: true,
      title: '원산지 증명',
      content: (
        <>
          <Text strong>* 예시 이미지</Text>
          <div className="my-3">
            <Image
              style={{ border: '1px #D0D5DD solid', borderRadius: '3px' }}
              src={process.env.PUBLIC_URL + '/cretificate_pictures_help.png'}
            />
          </div>
          <ul>
            <li>제품 원산지가 보이는 페이지를 캡쳐해 주세요</li>
          </ul>
        </>
      )
    });
  };

  return (
    <>
      <div className="py-4 px-3 bg-white mt-3">
        <div className="w-100 d-flex justify-content-between">
          <div className="w-100 d-flex flex-column mt-3">
            <Space className="no-width-space">
              <div style={{ fontSize: '18px', color: '#344054', fontWeight: '700', margin: '20px 0' }}>부가서비스</div>
              <Button onClick={openAdditionalServiceHelp} style={{ border: '0', padding: '0' }}>
                <QuestionCircleOutlined />
              </Button>
            </Space>
          </div>
        </div>
        <Divider className="my-2" />
        <Row justify="space-between" gutter={[10]}>
          <Col span={23}>
            <b style={{ fontSize: '13px' }}>FTA 서류 발급 신청, 150달러 이상 제품 필수</b>
          </Col>
          <Col span={1} className="d-flex justify-content-end align-items-baseline">
            <Form.Item name="fta_doc_issuance" valuePropName="checked" noStyle shouldUpdate>
              <Checkbox disabled={mode === 'view' || totalEstRefund < Math.min(...Object.values(fees))} />
            </Form.Item>
          </Col>
        </Row>
        <div className="py-2" style={{ color: '#F63D68', fontWeight: 700 }}>
          환급 예정 금액에서 7{currency?.label}가 차감됩니다
        </div>
        {/* Upload Invoice picture */}
        <Form.Item
          key={`certificate_pictures`}
          label={
            <div className="w-100 d-flex flex-column">
              <Space className="no-width-space">
                <div className="text-14 gray-900 fw-5">원산지 증명</div>
                <Button onClick={openCertificateImageHelp} style={{ border: '0', padding: '0' }}>
                  <QuestionCircleOutlined />
                </Button>
              </Space>
            </div>
          }
          name={'certificate_pictures'}>
          <>
            <UploadFileCustom
              accept=".png,.jpg,.jpeg,.pdf"
              upload_hint="PDF or JPG or PNG..."
              isViewOnly={mode === 'view' ? true : false}
            />
          </>
        </Form.Item>
      </div>
      <div className="py-4 px-3 bg-white mt-3">
        <div className="w-100 d-flex justify-content-between">
          <div style={{ fontSize: '18px', color: '#344054', fontWeight: '700', margin: '20px 0' }}>쿠폰</div>
        </div>
        <Row gutter={[10]}>
          <Col span={17}>
            <Form.Item key={`coupon_code`} name={'coupon_code'}>
              <Input size="large" disabled={couponAmount > 0} placeholder="쿠폰 코드를 입력해 주세요" />
            </Form.Item>
            <Form.Item key={`coupon_amount`} name={'coupon_amount'} style={{ display: 'none' }}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const couponAmount = getFieldValue('coupon_amount') || 0;
                return (
                  <Button
                    type={couponAmount > 0 ? 'default' : 'primary'}
                    size="large"
                    style={{ width: '100%' }}
                    onClick={() => {
                      if (couponAmount > 0) {
                        form.setFieldsValue({ coupon_code: '' });
                      }
                      handleCoupon();
                    }}>
                    {couponAmount > 0 ? '사용 취소' : '사용'}
                  </Button>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="py-4 px-3 bg-white mt-3 mb-3">
        {/* Total Prices */}
        <Row justify="space-between">
          <Col>환급 예상 금액</Col>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const total = getTotalEstRefund(getFieldValue);
              return formatCurrency(total, 2) + currency.label;
            }}
          </Form.Item>
        </Row>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const couponAmount = getFieldValue('coupon_amount');
            return couponAmount > 0 ? (
              <Row justify="space-between">
                <Col>쿠폰</Col>
                {formatCurrency(couponAmount, 2) + currency.label}
              </Row>
            ) : (
              <></>
            );
          }}
        </Form.Item>
        <Row justify="space-between">
          <Col>부가서비스 금액</Col>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const fee = getServiceFee(getFieldValue);
              return <Text type="danger">-{formatCurrency(fee, 2) + currency.label}</Text>;
            }}
          </Form.Item>
        </Row>
        <Row justify="space-between">
          <Col>할인 금액</Col>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const discount = getDiscount(getFieldValue);
              return <Text type="danger">-{formatCurrency(discount, 2) + currency.label}</Text>;
            }}
          </Form.Item>
        </Row>
        <Row justify="space-between">
          <Text strong>환급 예정 금액</Text>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              let final = getFinalEstRefund(getFieldValue);
              return <Text strong>{formatCurrency(final, 2) + currency.label}</Text>;
            }}
          </Form.Item>
        </Row>
      </div>
    </>
  );
};

export default TotalForm;
