import { Button, Col, Drawer, Row, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderFT2 from '../../../common/layout/HeaderFT2';

const { Title, Text, Paragraph } = Typography;

const ACITermsDrawer = ({ onClose, onClick, ...props }) => {
  const navigate = useNavigate();

  return (
    <Drawer
      placement="bottom"
      onClose={onClose}
      closeIcon={null}
      contentWrapperStyle={{ height: '100vh' }}
      headerStyle={{ border: 'none' }}
      bodyStyle={{ padding: '0px', width: '100%', overflowX: 'hidden'}}
      {...props}>
      <HeaderFT2
        title={
          <Title level={4} style={{ margin: 0 }}>
            운송 약관
          </Title>
        }
        onNavigate={onClose}
        isShadow={false}
        titleCenter={true}
        style={{ padding: '0px', borderBottom: '1px #ddd solid' }}
      />
      <Row gutter={[0, 16]} className='w-100' style={{padding: '0px 16px 41px 16px'}}>
        <Col span={24}>
          <div style={{textAlign: 'center'}}>
            <img className='w-100' src={process.env.PUBLIC_URL + '/aci_terms.png'}  />
          </div>
        </Col>
        <Col span={24}>
          <Text style={{ fontSize: '14px' }}>
            {'다음은 ACI Worldwide Express 국제운송 서비스 일체에 대해 적용하는 약관입니다. '}
            <br />
            {
              '만일 본 약관이 ACI Worldwide Express 화물 운송장 또는 기타 운송 서류의 내용과 상충되는 경우에는 본 약관이 우선하는 것으로 보며, ACI의 서비스를 이용하는 고객께서는 운송물 과 관련하여 ACI가 화물을 수거한 시점부터 본 약관 기준에 동의한 것으로 봅니다. 고객의 법적 인 권리나 권한은 운송약관에 의해 엄격히 제한됩니다.'
            }{' '}
            <br />
            {
              '운송물이란 하나의 송장(Waybill)하에 운송되는 모든 종류의 서류나 화물을 의미하며 ACI Worldwide Express에서 선택하는 항공운송, 해상운송, 육상운송 또는 기타 운송 편으로 운송 될 수 있습니다. 송장(Waybill)은 ACI Worldwide Express의 자동화 기기에서 제작되는 어떠한 라벨 형태나 화물운송장 또는 탁송물표 등을 모두 포함하며, 이 약관 기준의 적용을 받게 됩니다.'
            }
            <br />
            {
              '모든 물품은 하기 약관기준상의 제한된 책임기준에 기초하여 운송됩니다. "ACI Worldwide Express"이라 함은 ACI Worldwide Express의 모든 구성원을 의미합니다.'
            }
          </Text>
          <Title level={5}>1. 통관, 수출입</Title>
          <Text style={{ fontSize: '14px' }}>
            {
              'ACI Worldwide Express은 송하인을 대리하여 ACI Worldwide Express의 서비스를 제공하기 위해 다 음의 활동을 수행할 수 있습니다.'
            }{' '}
            <br />
            {
              '(1) 서류의 작성, 상품이나 서비스 Code 의 정정 또는 관련 법령이나 규정하의 관세나 세금의 지 불 등을 하게 되며'
            }{' '}
            <br />
            {
              '(2) 송하인의 수출 통관, 수출관리 목적의 대행자로서 또는 수하인의 수입통관 및 등록의 업무를 수행할 관세사의 지정을 위한 수하인으로의 역할'
            }
            <br />
            {
              '(3) ACI Worldwide Express에서 합리적인 의견에 따라 권한이 있는 자로 판단하는 자의 요청에 따 라 수하인의 통관 대행업자 또는 기타 주소로 운송물을 보낼 수 있습니다.'
            }
            <br />
            {
              '송하인 또는 수하인은 ACI Worldwide Express가 운송물을 통관하는데 관련 법령에 따라 추가적으 로 요구되는 어떠한 허가라도 제공하여야 합니다.'
            }
          </Text>
          <Title level={5}>2. 취급 불가 운송물</Title>
          <Text style={{ fontSize: '14px' }}>
            {'다음과 같은 경우 ACI Worldwide Express에서는 운송물은 취급 불가한 것으로 간주됩니다:'} <br />
            {
              '(1) IATA(국제항공운송협회) 또는 ICAO(국제민간항공협회), ADR(국제위험물도로운송규칙), 관련국정부, 기타 유관기관 등에 의해 독극물, 위험물, 금지 또는 제한된 품목'
            }
            <br />
            {'(2) 세관규칙상 필요함에도 세관신고를 하지 않은 경우'}
            <br />
            {
              '(3) 위조품, 동물류, 금괴류, 현금, 세금스티커, 유가증권, 귀금속; 실제(복제)화기류 혹은 부품, 무기 류, 폭발물 과 탄약; 유골, 포르노그라피, 혹은 불법약물이나 마약류 등을 포함한 안전하게 또는 합법적으로 운송할 수 없다고 판단하는 물품을 포함한 경우, 또는 포장에 결함이 있거나 적합하 지 않은 경우'
            }
            <br />
            {
              '(4) 주소가 부정확하거나 적절하게 표기되지 않은 경우 또는 포장에 하자가 있거나 포장이 통상 적인 주의에 의하여 취급되었을 때 안전한 운송을 보장하기에 적절하지 않은 경우'
            }
            <br />
            {
              '(5) 송하인, 수하인 또는 운송물에 직 간접적인 이해관계를 가지는 기타 제3자가 관련된 제재 목 록에 거절 또는 제한상대방으로 기재되어있는 경우'
            }
          </Text>
          <Title level={5}>3. 배송 가능 화물 및 배송 불가 화물</Title>
          <Text style={{ fontSize: '14px' }}>
            {
              '운송물은 우편사서함 이나 우편번호 만으로 배송될 수 없습니다. 운송물은 송하인이 제공한 수하 인의 주소로 배송되며 반드시 기재된 수하인 개인에게 배송되는 것은 아닙니다. 중앙 수취 지역 이 있는 주소로 배송되는 운송물은 해당 지역으로 배송됩니다.'
            }
            <br />
            {
              '운송물이 제 2 조에 따라 취급 불가한 것으로 간주되거나, 세관통관 목적상 저가 신고되었거나, 수하인이 합리적으로 신원 확인 또는 소재 파악이 되지 않거나, 수하인이 수령을 거절하거나 관 세 또는 기타 운송물 요금의 지급을 거부하는 경우, ACI Worldwide Express 는 해당 운송물을 송 하인에 반송하기 위하여 합리적인 노력을 하며, 이는 송하인의 비용으로 합니다. 이와 같이 운송 물을 반송할 수 없는 경우, 해당 운송물을 송하인 또는 기타 다른 자에 대한 일체의 책임 없이 이를 출고, 유기, 처분 할 수 있습니다. ACI Worldwide Express는 법률 또는 법 집행기관에 따라 ACI Worldwide Express가 송하인에게 전부 또는 일부를 반품하는 것이 금지된 운송물이나 위험 물품에 해당하는 운송물을 파기할 수 있는 권리를 보유합니다.'
            }
            <br />
          </Text>
          <Title level={5}>4. 검사</Title>
          <Text style={{ fontSize: '14px' }}>
            {
              'ACI Worldwide Express 는 안전, 보안, 통관 또는 기타 규제에 따라 통지 없이 운송물을 개봉 및 검사할 수 있는 권리를 보유합니다.'
            }
          </Text>
          <Title level={5}>5. 운임, 관세 및 수수료</Title>
          <Text style={{ fontSize: '14px' }}>
            {
              'ACI Worldwide Express의 운임은 실제중량과 부피중량 중 큰 것으로 계산 청구되며 ACI Worldwide Express 는 정확한 요금 산정을 위해 재계측을 할 수 있습니다. 고객께서는 ACI Worldwide Express를 이용하면서 또는 ACI Worldwide Express와 수하인 또는 관련 제 3자를 대리 하면서 초래된 운임 및 부대비용, 세금, 등과 운송물이 상기 제 2 조의 취급불가 운송물로 간주됨 으로 인한 클레임, 파손, 벌금, 비용 등 모든 비용을 지불하거나 변제 하여야 합니다.'
            }
            <br />
            {
              'ACI Worldwide Express는 통관수속을 완료하기 위하여 세관 공무원이 부과한 관세 및 세금을 고 객을 대신하여 지불하고 이로 인한 추가 요금을 산정할 수 있습니다.'
            }
            <br />
            {
              '송하인은 ACI Worldwide Express가 제공한 서비스에 대하여 ACI Worldwide Express에게 발생한 모 든 관세 및 각 현지국에서 발생된 기타 수수료들 또는 수하인이 지급하지 않아 송하인 또는 수하 인을 대신하여 ACI Worldwide Express에게 부과된 모든 비용을 ACI Worldwide Express에 지급하거 나 변제하여야 합니다.'
            }
          </Text>
          <Title level={5}>6. ACI Worldwide Express의 책임</Title>
          <Text style={{ fontSize: '14px' }}>
            {
              '화물의 운송 신고금액은 화물의 분실, 손상, 지연, 배달 착오, 배달 불능, 오보, 화물에 관련한 정 보 제공의 불이행, 또는 정보의 잘못된 전달 등 해당 화물의 운송에 관한 ACI Worldwide Express 의 최고 책임 한도를 의미합니다.'
            }
            <br />
            {
              '(1) 운송물을 운송함에 있어 항공운송과 육상운송 또는 다른 형태의 운송이 복합 사용되었다면 물품은 항공운송된 것으로 간주됩니다. 또한 해상운송과 육상운송 또는 다른 형태의 운송이 복합 사용 되었다면 물품은 해상운송된 것으로 간주됩니다.'
            }
            <br />
            {
              'ACI Worldwide Express의 운송에 관한 책임은 INVOICE 신고 가격 또는 USD 100.00 중 더 낮은 가격으로 제한되는 것으로 간주됩니다. 이와 같은 제한은 해당 국가의 운송 관련 법률상 강제적 인 또는 더 낮은 수준의 책임 제한이 존재하지 않는 경우 국내 도로 운송에도 적용됩니다. 송하 인은 이와 같은 제한이 불충분하다고 판단하는 경우 운송물 보험서비스를 자체적으로 보험에 가 입하여야 합니다. 고객은 보험에 가입함으로써 당해 위험을 고객의 선택으로 보험회사에 이전할 수 있습니다. 보험 적용을 원하는 경우 고객은 보험 대리점 또는 중개인에게 문의해야 합니다. 당 사는 어떠한 종류의 보험 도 제공하지 않습니다'
            }
            <br />
            {
              'ACI Worldwide Express의 책임은 오직 운송물에 대한 직접적 손해 및 손상 그리고 본 제6조의 약 관으로 엄격하게 제한됩니다. 기타 다른 형태의 손해 및 손상은 당해 손해 및 손상이 특별하거나 간접적인 손해나 손상인지 여부를 불문하고, 그리고 해당 손해 및 손상의 위험을 ACI Worldwide Express 가 인지한 경우라고 하더라도 제외됩니다(일실이익, 소득, 이자, 미래의 사업기회를 포함 하나 이에 국한되지 아니함).'
            }
            <br />
            {'(2) ACI Worldwide Express는 ACI Worldwide Express의 정기적인 배송 일정에 따라 운송물을 배송'}
            <br />
            {
              '하기 위하여 모든 합리적인 노력을 기울이며, 그렇다 하더라도 이와 같은 일정은 구속력을 갖지 아니하며 계약의 일부를 구성하지 않습니다. ACI Worldwide Express는, ACI Worldwide Express의 귀 책사유로 인하여 부당하게 오래 지연된 경우가 아닌 한, 지연으로 인한 손해에 대해 책임을 지지 않습니다. 그리고 어떠한 경우에도 ACI Worldwide Express는 본 약관 제6조에 규정된 한도를 넘어 책임을 지지 않으며, 지연으로 인한 결과손해, 간접손해, 특별손해에 대해서도 책임을 지지 않습 니다.'
            }
          </Text>
          <Title level={5}>7. 손해배상의 청구</Title>
          <Text style={{ fontSize: '14px' }}>
            {
              '모든 클레임은 ACI Worldwide Express 가 운송물을 수령한 일자로부터 20 일 이내에 서면으로 ACI Worldwide Express 에 제출되어야 하며 그렇지 않은 경우 ACI Worldwide Express 은 일체의 책임을 부담하지 않습니다. 클레임은 운송물 당 1 회의 클레임으로 제한되며 해당 클레임의 합의는 그와 관련된 모든 손해 또는 피해에 대한 완전하고 최종적인 합의가 됩니다.'
            }
          </Text>
          <Title level={5}>8. 통제불능상황</Title>
          <Text style={{ fontSize: '14px' }}>
            {
              'ACI Worldwide Express 는 ACI Worldwide Express 의 통제를 벗어난 상황에 의하여 발생한 손해나 손상에 대하여 일체 책임을 지지 않습니다. 여기에는 전자 또는 사진 이미지, 데이터 또는 기록물의 전기적 또는 자성에 의한 손상이나 삭제, (ACI Worldwide Express 에 고지된 경우를 포함하여) 운송물의 성격과 관련된 결함이나 특성, 송하인, 수하인, 제 3 자, 세관 또는 기타 공무원과 같이 ACI Worldwide Express 가 고용하거나 ACI Worldwide Express 와 계약하지 않은 자에 의한 작위나 부작위, 제 3 자에 의한 사이버 공격 또는 기타 정보보안 관련 위해, 및 지진, 사이클론, 폭풍, 홍수, 안개, 전쟁, 항공기 추락, 금수조치, 폭동, 전염병, 전 세계적 유행병, 내란 또는 노동쟁의 행위와 같은 “불가항력”적 사유가 포함되나 이에 국한되지 않습니다.'
            }
          </Text>
          <Title level={5}>9. 송하인의 진술, 보장 및 면책</Title>
          <Text style={{ fontSize: '14px' }}>
            {
              '송하인은 송하인이 다음과 같은 진술 및 보장을 준수하지 않아 발생한 모든 책임, 손해 및 손실 에 대하여 ACI Worldwide Express의 모든 임직원 및 대리인을 면책하고 이를 보상합니다.'
            }
            <br />
            {'(1) 운송물은 상기 제 2 조에 따라 취급이 허용된 물품입니다.'}
            <br />
            {
              '(2) 운송물은 신뢰할 수 있는 자에 의하여 안전한 장소에서 준비되었고 운송물이 준비, 보관 및 ACI Worldwide Express에 인도되는 동안 허가되지 않은 개입으로부터 보호되었습니다.'
            }
            <br />
            {
              '(3) 송하인은 물품의 수입, 수출, 수송 또는 이동과 관련된 모든 수출관리, 제재, 통관 관련 법령 및 기타 적용되는 규제 요건과 제한사항들을 준수하였습니다.'
            }
            <br />
            {
              '(4) 송하인은 ACI Worldwide Express 에게 운송물에 포함된 정부허가 대상인 모든 관리대상 군민 양용 또는 군용 제품에 대해 신고하였습니다.'
            }
            <br />
            {
              '(5) 송하인은 관련 법령 또는 ACI Worldwide Express가 요구하는 모든 정보, 허가증, 라이선스, 또 는 기타 정부허가 및 서류들을 제공하였으며, 송하인 또는 그 대표자가 이와 같이 제공한 모든 정보, 허가증, 라이선스, 또는 기타 정부허가 및 서류들은 사실이고, 완전하고 정확하며, 물품에 대 한 가치, 설명 및 송하인과 수하인에 관한 정보가 포함됩니다.'
            }
            <br />
            {
              '(6) ACI Worldwide Express 에게 개인 정보를 제공함에 있어, 송하인은 이와 같은 정보를 공유 및 처리함에 필요한 모든 법적 의무(해당되는 개인에게 수하인의 이메일 주소와 핸드폰 번호를 포함 한 개인 정보가 운송물의 운송, 통관 및 배송에 필요하다는 내용을 알리는 것 포함)를 준수하였습 니다.'
            }
          </Text>
          <Title level={5}>10. 운송경로</Title>
          <Text style={{ fontSize: '14px' }}>
            {'송하인은 운송물이 중간 경유지를 거쳐 운송될 가능성을 포함하여 모든 경로 지정 및 변경에 동 의 합니다.'}
          </Text>
          <Title level={5}>11. 준거법</Title>
          <Text style={{ fontSize: '14px' }}>
            {
              '본 국제운송에서 발생하는 모든 문제는 대한민국법에 의해 규율되고 해석 되며, 모든 분쟁은 대 한민국의 서울중앙지방법원을 전속적 합의 관할로 정합니다.'
            }
          </Text>
          <Title level={5}>12. 조항의 독립성</Title>
          <Text style={{ fontSize: '14px' }}>
            {
              '본 약관의 일부 조항이 무효가 되거나 또는 집행이 불가능하더라도, 이는 본 약관의 다른 부분에 영향을 미치지 않습니다.'
            }
          </Text>
        </Col>
        <Col span={24}>
          <Button block type="primary" size="large" onClick={onClick}>
            ACI 계정 계약 동의하기
          </Button>
        </Col>
      </Row>
    </Drawer>
  );
};

export default ACITermsDrawer;
