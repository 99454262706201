import { Button, Drawer, Form, Input, Modal, Typography, message } from 'antd';
import React from 'react';
import apiClient from '../../../services/axios';

const { Title, Text } = Typography;

const ForgotPasswordDrawer = ({ onClose, ...props }) => {
  const [loading, setLoading] = React.useState(false);
  const [modalOpened, setModalOpened] = React.useState(false);

  const submit = async ({ email }) => {
    try {
      setLoading(true);
      await apiClient.post('user/forgot-password', { email });
      message.success(`이메일이 다음 주소로 전송되었습니다 ${email}`);
      onClose();
    } catch (e) {
      if (e.response?.data?.code === 'forgot_password_email_not_exists') {
        setModalOpened(true);
      } else {
        message.error('잘못된 요청');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      placement="bottom"
      onClose={onClose}
      contentWrapperStyle={{ height: 'auto' }}
      style={{ borderRadius: '12px 12px 0px 0px' }}
      headerStyle={{ border: 'none' }}
      bodyStyle={{ padding: '0px 16px 41px 16px' }}
      {...props}>
      <Title level={3} style={{ textAlign: 'center' }}>
        비밀번호 재설정
      </Title>
      <Text type="secondary" style={{ display: 'block', textAlign: 'center' }}>
        {'걱정하지 마세요! 저희도 가끔 잃어버린답니다 :)'}
      </Text>
      <Form name="forgot_password" layout="vertical" colon={false} onFinish={submit}>
        <Form.Item label="이메일" name="email" rules={[{ type: 'email', message: '이메일 형식이 올바르지 않아요' }]}>
          <Input size="large" />
        </Form.Item>
        <Form.Item
          shouldUpdate
          extra={<Text type="secondary">가입하신 이메일로 비밀번호 재설정 링크를 전송합니다</Text>}
          style={{ marginTop: '24px' }}>
          {({ getFieldsValue }) => (
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              block
              disabled={!getFieldsValue().email}
              loading={loading}>
              이메일 전송
            </Button>
          )}
        </Form.Item>
      </Form>
      <Text underline style={{ display: 'block', textAlign: 'center' }}>
        비밀번호가 생각나셨나요?{' '}
        <Text onClick={onClose} strong>
          로그인
        </Text>
      </Text>
      <Modal
        open={modalOpened}
        centered
        closable={false}
        cancelText="닫기"
        onCancel={() => setModalOpened(false)}
        cancelButtonProps={{ block: true, size: 'large' }}
        okButtonProps={{ style: { display: 'none' } }}>
        <Text style={{ fontSize: '16px', fontWeight: 500 }}>
          이메일이 존재하지 않습니다
          <br />
          다시 한번 확인해 주세요
        </Text>
      </Modal>
    </Drawer>
  );
};

export default ForgotPasswordDrawer;
