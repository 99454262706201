import { Select, notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { CURRENCIES } from '../../../../common/utilities/constants';
import { fetchCurrency } from '../api';

export default function CurrencySelect({ selectedCurrency, setSelectedCurrency, ...props }) {
  const [loading, setLoading] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState([]);

  const fetchApi = useCallback(async () => {
    try {
      setLoading(true);
      const params = { names: [CURRENCIES.EUR.name, CURRENCIES.GBP.name], page: 1, size: 500 };
      const data = await fetchCurrency(params);
      const options = data.resources
        .map((r) => ({
          value: r.id,
          label: CURRENCIES[r.name]?.label || r.name
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setCurrencyOptions(options);
      if (!selectedCurrency) {
        setSelectedCurrency(options[0]?.value);
      }
    } catch (error) {
      notification.error({ message: '화폐를 얻을 수 없습니다' });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <Select
      size="large"
      options={currencyOptions}
      value={selectedCurrency}
      onSelect={setSelectedCurrency}
      loading={loading}
      {...props}
    />
  );
}
