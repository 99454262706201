import { useCallback } from 'react';
import AsyncSelect from '../../../../common/components/AsyncSelect/AsyncSelect';
import { fetchBanks } from '../../../../common/api';

export default function BankAsyncSelect({ ...props }) {
  const fetchApi = useCallback(async () => {
    try {
      const { resources = [] } = await fetchBanks({ page: 1, size: 500, is_cafe24: false });
      return resources.map((r) => ({ value: r.id, label: r.name }));
    } catch (e) {
      console.error(e);
      return [];
    }
  }, []);

  return <AsyncSelect placeholder="Bank" allowClear fetchApi={fetchApi} {...props} />;
}
