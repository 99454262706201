import { Button, Col, Drawer, Input, message, Modal, Pagination, Row, Select, Space, Spin, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { Search, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ChevronDown } from '../../../assets/icons/Icons';
import ActionIcon from '../../../common/components/ActionIcons';
import HeaderFT from '../../../common/layout/HeaderFT';
import { fetchRefundRequestStatusThunk } from '../../../redux/reducers/refundRequestSlice';
import {
  changePage,
  fetchRefundRequestThunk,
  openSearchDrawer,
  closeSearchDrawer
} from '../../../redux/reducers/refundRequestSlice';
import { fetchUserInfoThunk } from '../../../redux/reducers/authSlice';
import ProductCard from '../../features/refundRequest/components/ProductCard';
import SearchFilter from './SearchFilter';
import './style.scss';
import { COUNTRIES, USER_ROLE } from '../../../common/utilities/constants';
import { closeSideBar } from '../../../redux/reducers/layoutSlice';
import apiClient from '../../../services/axios';
const { Text } = Typography;
const RefundRequestPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selected, setSelected] = useState('all');
  const [countrySelected, setCountrySelected] = useState(COUNTRIES.france.value);
  const [isUserFetch, setIsUserFetch] = useState(false);
  const [openRejectReasonModal, setOpenRejectReasonModal] = useState(false);
  const [openDeliveryPendingModal, setOpenDeliveryPendingModal] = useState(false);
  const [deliveryPendingData, setDeliveryPendingData] = useState({});
  const [openCreateRequestModal, setOpenCreateRequestModal] = useState(false);
  const [rejectReason, setRejectReason] = useState(null);
  const [rejectId, setRejectId] = useState(null);
  const [searchParams] = useSearchParams();
  const [searchData, setSearchData] = useState({});
  const [sortBy, setSortBy] = useState('updated_at');

  const { userInfo } = useSelector((state) => state.auth);
  const { totalResources, page, size, resources, loading, statusOptions, isOpenSearchDrawer, filters } = useSelector(
    (state) => state.refundRequests
  );

  const key = searchParams.get('key') || 'all';
  const countryKey = searchParams.get('country') || COUNTRIES.france.value;
  const STATUS_OPTIONS = [
    statusOptions.find((s) => s.value === 'all'), // move 'all' to the top
    ...statusOptions.filter((s) => s.value !== 'all')
  ].filter(Boolean);

  useEffect(() => {
    dispatch(fetchUserInfoThunk());
    setSelected(key);
    setCountrySelected(countryKey);
    setIsUserFetch(true);
  }, []);

  useEffect(() => {
    dispatch(fetchRefundRequestStatusThunk({ country: countrySelected }));
  }, [countrySelected]);

  useEffect(() => {
    if (!userInfo?.active && userInfo?.role === USER_ROLE.FreeTBusiness) {
      navigate('/inactive');
    }
    if (isUserFetch && userInfo && userInfo?.registered === false) {
      dispatch(closeSideBar());
      navigate('/onboarding');
    }
    if (userInfo?.active && (selected === key || countrySelected === countryKey)) {
      dispatch(fetchRefundRequestThunk({ ...filters, status: selected, country: countrySelected, page, size }));
    }
  }, [userInfo, page, selected, countrySelected]);

  const handleChangeSelect = (value) => {
    setSelected(value);
    if (value) {
      navigate({
        search: createSearchParams({ key: value, country: countrySelected }).toString()
      });
    } else {
      navigate({
        search: createSearchParams({}).toString()
      });
    }
  };

  const handleCountriesSelect = (value) => {
    setCountrySelected(value);
    if (value) {
      navigate({
        search: createSearchParams({ key: selected, country: value }).toString()
      });
    } else {
      navigate({
        search: createSearchParams({}).toString()
      });
    }
  };

  const handleOnclickSubmitNewRefundRequest = () => {
    setOpenCreateRequestModal(true);
    // navigate('/create-refund-request');
  };

  const handleChangePagination = (current) => {
    dispatch(changePage(current));
  };

  const handleOpenRejectModal = (id, reason) => {
    setOpenRejectReasonModal(true);
    setRejectId(id);
    setRejectReason(reason);
  };

  const handleCloseRejectModal = () => {
    setOpenRejectReasonModal(false);
    setRejectId(null);
    setRejectReason(null);
  };

  const handleDeliveryPendingModal = (data) => {
    setDeliveryPendingData(data);
    setOpenDeliveryPendingModal(true);
  };

  const refresh = () => {
    dispatch(fetchRefundRequestThunk({ ...filters, status: selected, page, size }));
  };

  return (
    <>
      <HeaderFT
        title={'부가세환급 신청'}
        rightIems={
          <ActionIcon
            style={{ padding: '0px', marginRight: '10px' }}
            icon={<Search size="24px" />}
            onClick={() => dispatch(openSearchDrawer())}
          />
        }
      />
      <Content style={{ paddingBottom: '50px' }}>
        <div className="container-page">
          <div
            onClick={() => {
              // navigate to refund-destination page
              navigate('/refund-destination');
            }}
            className="d-flex justify-content-between"
            style={{
              height: '84px',
              backgroundColor: '#3431D6',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '5px',
              marginTop: '24px',
              padding: '20px 24px',
            }}>
            <div className="d-flex justify-content-between flex-column">
              <Text className="text-white" style={{fontSize: '16px', fontWeight: '700', fontFamily: 'Gmarket Sans TTF', fontStyle: 'normal', lineHeight: '24px'}}>인보이스 발급 방법</Text>
              <Text className="text-white" style={{fontSize: '14px', fontWeight: '500', fontFamily: 'Gmarket Sans TTF', fontStyle: 'normal', lineHeight: '20px'}}>이용가이드 바로가기 {'->'}</Text>
            </div>
            <img src={process.env.PUBLIC_URL + '/book.png'} style={{width: '92px', height: '64px', marginTop: '-10px'}} />
          </div>
          <Row gutter={10}>
            <Col span={8}>
              <Select
                value={countrySelected}
                onChange={handleCountriesSelect}
                style={{
                  width: '100%',
                  marginTop: '24px',
                  marginBottom: '22px',
                  border: '1px solid #D0D5DD',
                  borderRadius: '5px'
                }}
                suffixIcon={<ChevronDown />}
                bordered={false}
                size="large"
                options={Object.values(COUNTRIES)}
              />
            </Col>
            <Col span={16}>
              <Select
                value={selected}
                onChange={handleChangeSelect}
                style={{
                  width: '100%',
                  marginTop: '24px',
                  marginBottom: '22px',
                  border: '1px solid #D0D5DD',
                  borderRadius: '5px'
                }}
                suffixIcon={<ChevronDown />}
                bordered={false}
                size="large"
                options={STATUS_OPTIONS}
              />
            </Col>
          </Row>
          <Spin tip="Loading" spinning={loading}>
            {resources?.length > 0 ? (
              <>
                <Row>
                  {resources?.map((item, idx) => {
                    return (
                      <Col key={idx} xs={{ span: 24 }} lg={{ span: 24 }} className="mb-3">
                        <ProductCard
                          data={item}
                          key={idx}
                          handleOpenRejectModal={handleOpenRejectModal}
                          handleDeliveryPendingModal={handleDeliveryPendingModal}
                          refresh={refresh}
                        />
                      </Col>
                    );
                  })}
                </Row>
                <div className="d-flex justify-content-center mt-1">
                  <Pagination
                    defaultCurrent={page}
                    pageSize={size}
                    total={totalResources}
                    onChange={handleChangePagination}
                  />
                </div>
              </>
            ) : (
              <div
                className="d-flex justify-content-center flex-column justify-content-center"
                style={{ marginTop: '10vh', alignItems: 'center' }}>
                <img src={process.env.PUBLIC_URL + '/empty.png'} style={{ width: '183px' }} />
                <p style={{ textAlign: 'center', fontWeight: '700' }}>신청 내역이 없어요</p>
              </div>
            )}
          </Spin>
        </div>

        <Button className="submit-new-btn" type="primary" block onClick={handleOnclickSubmitNewRefundRequest}>
          신청서 작성
        </Button>
        <SearchFilter
          open={isOpenSearchDrawer}
          onSaveSearchData={setSearchData}
          searchData={searchData}
          onClose={() => dispatch(closeSearchDrawer())}
        />
      </Content>
      <Modal
        closable={false}
        maskClosable={false}
        open={openDeliveryPendingModal}
        footer={false}
        centered
        title={
          <div className="gray-900 fw-7 mb-3" style={{ fontSize: '16px' }}>
            {deliveryPendingData?.delivery_pending ? '출고 보류 해제' : '출고 보류'}
          </div>
        }>
        <div className="w-100">
          {deliveryPendingData?.delivery_pending ? (
            <>
              <Text className="w-100">
                출고 보류를 해제 하려면 아래 <strong style={{ color: '#F63D68' }}>{'"출고 보류 해제"'}</strong> 버튼을
                선택해 주세요
              </Text>
            </>
          ) : (
            <>
              <Text className="mb-4 w-100">
                해당 신청서를 출고 보류 하려면 아래 <strong style={{ color: '#F63D68' }}>{'"출고 보류"'}</strong> 버튼을
                선택해 주세요
              </Text>
              <br />
              <br />
              <Text className="w-100 gray-900" style={{ color: '#F63D68' }}>
                *출고 보류는 원할 때 언제든지 해제할 수 있어요
              </Text>
            </>
          )}
        </div>
        <div className="w-100 mt-4 d-flex">
          <Button block onClick={() => setOpenDeliveryPendingModal(false)} className="me-3" size="large">
            닫기
          </Button>
          <Button
            block
            type="primary"
            size="large"
            style={{
              backgroundColor: deliveryPendingData?.delivery_pending ? '#FECDD6' : '#F63D68',
              color: deliveryPendingData?.delivery_pending ? '#000' : '#FFF'
            }}
            onClick={async () => {
              try {
                const result = await apiClient.post(`/refund-request/${deliveryPendingData?.id}/delivery-pending`);
                if (result?.status === 201) {
                  refresh();
                  message.success('Success!');
                } else {
                  message.success('Failed! Please try again!');
                }
              } catch (error) {
                message.success('Failed! Please try again!');
              } finally {
                setOpenDeliveryPendingModal(false);
              }
            }}>
            {deliveryPendingData?.delivery_pending ? '출고 보류 해제' : '출고 보류'}
          </Button>
        </div>
      </Modal>
      <Drawer
        title={false}
        placement={'bottom'}
        closable={false}
        onClose={() => handleCloseRejectModal()}
        open={openRejectReasonModal}
        rootClassName="optiosDrawer"
        contentWrapperStyle={{
          height: '250px',
          // maxHeight: '420px',
          overflow: 'scroll'
        }}>
        <div className="d-flex justify-content-between">
          <p
            style={{
              fontSize: '16px',
              color: '#101828',
              fontWeight: '700'
            }}>
            반려 사유
          </p>
          <X style={{ marginBottom: '20px', fontSize: '18px' }} onClick={() => handleCloseRejectModal()} />
        </div>
        <div>
          <Input.TextArea rows={3} value={rejectReason} readOnly />
          <Button
            type="primary"
            block
            size="large"
            className="mt-4"
            onClick={() => navigate(`/refund-request/edit?id=${rejectId}`)}>
            제품 수정
          </Button>
        </div>
      </Drawer>
      {/* Choose Refund country and then navigate to create refund request page*/}
      <Drawer
        title={'환급 신청할 국가를 선택해 주세요'}
        placement={'bottom'}
        onClose={() => setOpenCreateRequestModal(false)}
        open={openCreateRequestModal}
        rootClassName="optiosDrawer"
        height="auto"
        headerStyle={{
          borderBottom: '0px',
          padding: '12px 24px'
        }}
        style={{
          borderRadius: '12px 12px 0px 0px'
        }}>
        {Object.values(COUNTRIES)?.map((country, idx) => (
          <Button
            className="w-100 mb-3 p-2"
            style={{ borderRadius: '5px', height: '100%' }}
            hoverable={false}
            key={`country${idx}`}
            onClick={() => navigate(`/create-refund-request?country=${country.value}`)}
            disabled={country.disabled}>
            <div className="d-flex justify-content-between w-100">
              <Space>
                <div style={{ lineHeight: '0' }}>{country.icon}</div>
                <Text>{country.label}</Text>
              </Space>
              {country.disabled ? (
                <Text className="pt-1">
                  <strong style={{ color: 'red' }}>서비스 준비중이에요</strong>
                </Text>
              ) : (
                <Text className="pt-1">
                  {`부가세 ${country.vat_rate * 100}% 중`}{' '}
                  <strong style={{ color: 'red' }}>{country.refund_rate * 100 + '%'}</strong> {`환급`}
                </Text>
              )}
            </div>
          </Button>
        ))}
      </Drawer>
    </>
  );
};

export default RefundRequestPage;
