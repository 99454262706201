import InputNumberCustom from './InputNumberCustom';
import React from 'react';
import './style.scss';

const InputEuroCurrency = ({ ...remain }) => {
  return (
    <InputNumberCustom
      prefix="€"
      formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value.replace(/€\s?|(,*)/g, '')}
      controls={false}
      min={0}
      defaultValue={0}
      {...remain}
    />
  );
};

export default InputEuroCurrency;
