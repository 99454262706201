import './style.scss';
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Layout,
  message,
  Modal,
  Segmented,
  Select,
  Spin,
  Row,
  Space,
  Typography
} from 'antd';
import { every, isArray, isEmpty, set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import apiClient from '../../../services/axios';
import HeaderFT2 from '../../../common/layout/HeaderFT2';
import ActionIcon from '../../../common/components/ActionIcons';
import RequestInfo from '../../features/refundRequest/components/RequestInfo';
import { fetchDefaultRecipientThunk } from '../../../redux/reducers/refundRequestSlice';
import { getArea, getPhoneNumber } from '../../../utility/utils';
import { COUNTRIES, REFUND_REQUEST_STATUS, USER_ROLE } from '../../../common/utilities/constants';
import BulkCreateRefundRequest from './BulkCreateRefundRequest';
import dayjs from 'dayjs';
import { ChevronDown } from '../../../assets/icons/Icons';
import { fetchCoupon } from '../../features/common/api';
const { Content } = Layout;
const { Text } = Typography;

const CreateNewRefundRequest = ({ action = 'new' }) => {
  const [form] = Form.useForm();
  // const values = Form.useWatch([], form);
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [statusRequest, setStatusRequest] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [statusRequestTag, setStatusRequestTag] = useState('');
  const [formType, setFormType] = useState('single');
  const requestId = ['edit', 'view'].includes(action) ? searchParams.get('id') : undefined;
  const countryRequest = searchParams.get('country') || COUNTRIES.france.value;
  const returnTo = searchParams.get('return_to') || 'refund-request';
  const [country, setCountry] = useState(countryRequest);
  const [objId, setObjId] = useState(null);
  const { userInfo } = useSelector((state) => state.auth);
  const { default_recipient } = useSelector((state) => state.refundRequests);
  const [submittable, setSubmittable] = useState(false);
  const countries_options = Object.values(COUNTRIES).map((c) => ({
    value: c.value,
    label: (
      <Space>
        <div style={{ lineHeight: '0' }}>{c.icon}</div>
        <Text disabled={c.disabled}>{c.label}</Text>
      </Space>
    ),
    disabled: c.disabled
  }));

  function getParams(s) {
    const params = { step: s };
    if (requestId) {
      params['id'] = requestId;
      if (action === 'view') {
        params['step'] = 2;
        if (searchParams.get('return_to')) {
          params['return_to'] = searchParams.get('return_to');
        }
      }
    } else {
      params['country'] = countryRequest;
    }
    return params;
  }

  useEffect(() => {
    setSearchParams(getParams('1'));
    dispatch(fetchDefaultRecipientThunk());
  }, []);

  useEffect(() => {
    const couponAmount = form.getFieldValue('coupon_amount');
    if (couponAmount > 0) {
      const currency = COUNTRIES?.[country]?.currency || COUNTRIES.france.currency;
      form.setFieldsValue({ coupon_code: `${couponAmount}${currency.label} 할인 쿠폰` });
    } else {
      form.setFieldsValue({ coupon_code: '' });
    }
  }, [form.getFieldValue('coupon_amount')]);

  useEffect(() => {
    handleScrollTop();
  }, [step]);

  useEffect(() => {
    if (action === 'new' && default_recipient !== null) {
      const area = getArea(default_recipient?.phone);
      const phone = default_recipient?.phone?.replace(area?.code || '', '0');
      form.setFieldsValue({
        ...default_recipient,
        areaCode: area?.value,
        delivery_date: null,
        phone
      });
    }
    if (action === 'new' && userInfo?.phone) {
      form.setFieldValue('phone', userInfo?.phone);
    }
  }, [default_recipient, userInfo]);

  useEffect(() => {
    if (requestId) {
      fetchRequest(requestId);
    }
  }, [requestId]);

  useEffect(() => {
    if (action === 'edit' && statusRequest && !['pending', 'rejected'].includes(statusRequest)) {
      message.error("This request doesn't edit");
      navigate('/refund-request');
    }
    const config = REFUND_REQUEST_STATUS.filter((i) => i.value === statusRequest)?.[0];
    if (config) {
      setStatusRequestTag(config.label);
    }
  }, [statusRequest]);

  const fetchRequest = async (id) => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(`/refund-request/${id}`);
      if (data) {
        setObjId(data?.id);
        setStatusRequest(data?.status);
        setCountry(data?.country || COUNTRIES.france.value);
        const area = getArea(data?.phone);
        const phone = data?.phone?.replace(area?.code || '', '0');
        setCouponCode(data?.coupon?.code);
        const couponAmount = data?.coupon_amount || 0;
        const currency = COUNTRIES?.[data?.country]?.currency || COUNTRIES.france.currency;
        form.setFieldsValue({
          ...data,
          delivery_date: data.delivery_date ? dayjs(data.delivery_date) : null,
          areaCode: area?.value,
          coupon_amount: couponAmount,
          coupon_code: couponAmount > 0 ? `${couponAmount}${currency.label} 할인 쿠폰` : '',
          phone,
          // products: data.products
          invoices: data?.invoices?.map((invoice) => {
            return {
              ...invoice,
              products: invoice?.products?.map((product) => {
                return {
                  ...product,
                  vat: Number((product.vat / product.quantity).toFixed(2))
                };
              })
            };
          })
        });
      } else {
        message.error('error');
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.get('step') > 2) {
      handleToStep1();
    } else {
      setStep(+searchParams.get('step'));
    }
  }, [searchParams.get('step')]);

  const handleToStep2 = () => {
    setSearchParams(getParams('2'));
  };

  const handleToStep1 = () => {
    setSearchParams(getParams('1'));
  };

  const onNavigate = () => {
    if (action === 'view') {
      navigate(`/${returnTo}`);
    } else {
      handleToStep1();
    }
  };

  const handleCoupon = async () => {
    const coupon_code = form.getFieldValue('coupon_code');
    const coupon_amount = form.getFieldValue('coupon_amount');
    if (coupon_amount > 0) {
      form.setFieldsValue({ coupon_amount: 0, coupon_code: '' });
      return;
    }
    setLoading(true);
    const data = await fetchCoupon(coupon_code);
    setCouponCode(coupon_code);
    if (data) {
      form.setFieldsValue({ coupon_amount: data?.amount });
    } else {
      message.error('Coupon is invalid');
      form.setFieldsValue({ coupon_amount: 0 });
    }
    setLoading(false);
  };

  const handleValidateProducts = (products) => {
    // At least one product must be provided
    if (products.length === 0 || products.length > 10) return false;
    // Validate each product
    const ignoredFields = [
      'product_origin',
      'memo',
      'deleted_at',
      'approved_at',
      'rejected_at',
      'invoice_number_eu',
      'refund_request_id',
      'pictures',
      'invoice_pictures'
    ];
    for (const product of products) {
      for (const key in product) {
        if (ignoredFields.includes(key)) {
          continue;
        }
        if (!product[key]) {
          return false;
        } else if (isArray(product[key]) && isEmpty(product[key])) {
          return false;
        }
      }
    }
    return true;
  };

  const handleValidateInvoices = (invoices) => {
    // At least one product must be provided
    if (invoices.length === 0 || invoices.length > 10) return false;
    // Validate each product
    const ignoredFields = [
      // 'pictures',
      'discount',
      'deleted_at',
      'invoice_number',
      'arrived'
    ];
    for (const invoice of invoices) {
      const isValidate = every(invoice, (value, key) => {
        if (ignoredFields.includes(key)) return true;
        if (key === 'products') return handleValidateProducts(value);
        if (isArray(invoice[key]) && isEmpty(invoice[key])) {
          return false;
        }
        return !!value;
      });
      if (!isValidate) {
        return false;
      }
    }
    return true;
  };

  const handleValidateForm = (values) => {
    const ignoredFields = [
      'fta_doc_issuance',
      'certificate_pictures',
      // 'product_pictures',
      'memo',
      'collect_customs',
      'tax_payment',
      'invoice_number',
      'deleted_at',
      'approved_at',
      'rejected_at',
      'delivery_date',
      // 'discount',
      'tracking_number',
      'delivery_agency',
      'full_arrived',
      'coupon_code',
      'coupon_amount',
      'address_en',
      'recipient_en',
    ];
    const isValidate = every(values, (value, key) => {
      if (ignoredFields.includes(key)) return true;
      // if (key === 'products') return handleValidateProducts(value);
      if (key === 'invoices') return handleValidateInvoices(value);
      return !!value;
    });
    return !isValidate;
  };

  const onSubmit = async () => {
    const body = form.getFieldsValue();
    body['phone'] = getPhoneNumber(body.areaCode, body.phone);
    body.products?.map((product) => {
      product.invoice_pictures?.map((pic) => {
        pic.picture_type = 'invoice';
      });
    });
    if (userInfo.role === USER_ROLE.FreeTBusiness) {
      body['fta_doc_issuance'] = false;
      // body['store_pickup'] = false;
    }
    if (couponCode) {
      body['coupon_code'] = couponCode;
    }
    body['country'] = country;
    body?.invoices?.forEach((invoice) => {
      invoice.discount = invoice?.discount || 0;
    });
    body?.certificate_pictures?.forEach((pic) => (pic.picture_type = 'certificate'));
    setLoading(true);
    try {
      const res = objId
        ? await apiClient.put(`/refund-request/${objId}`, body)
        : await apiClient.post('/refund-request', body);
      message.success('Successfully');
      setLoading(false);
      if (res?.data?.id) {
        navigate(`/refund-request/completed?id=${res?.data?.request_ref || res?.data?.id}`);
      } else {
        navigate('/refund-request');
      }
    } catch (error) {
      message.error(error?.response?.data?.message || 'Error');
      setLoading(false);
    }
  };

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleClickSubmitBtn = async () => {
    await onSubmit();
  };

  const onEditMode = () => {
    if (action === 'new') {
      handleToStep1();
    } else {
      if (action === 'view' && ['pending', 'rejected'].includes(statusRequest)) {
        navigate(`/refund-request/edit?id=${requestId}&step=1`, { replace: true });
      }
    }
  };

  const deleteRequest = async (id) => {
    try {
      const res = await apiClient.delete(`/refund-request/${id}`);
      if (res?.status === 204) {
        Modal.success({
          title: <div className="text-18">삭제되었습니다</div>,
          onOk: () => navigate('/refund-request'),
          centered: true,
          icon: null,
          okText: '닫기',
          okButtonProps: {
            type: 'default',
            size: 'large',
            style: {
              width: '100%'
            }
          }
        });
      } else {
        message.error('Failed to delete the Request');
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'error');
    }
  };

  const onDelete = () => {
    Modal.confirm({
      title: <div className="text-18">신청서를 삭제 하시겠습니까?</div>,
      onCancel: () => { },
      onOk: () => {
        deleteRequest(objId);
      },
      centered: true,
      icon: null,
      cancelText: '닫기',
      okText: '삭제',
      okButtonProps: {
        danger: true,
        type: 'primary',
        size: 'large',
        style: {
          // marginRight: '8%',
          width: '48%',
          backgroundColor: '#f63d68'
        }
      },
      cancelButtonProps: {
        style: {
          width: '48%'
        },
        size: 'large'
      }
    });
  };

  const theme = {
    components: {
      Segmented: {
        itemActiveBg: '#F2F4F7',
        itemColor: '#344054',
        itemSelectedBg: '#10264D',
        itemSelectedColor: '#FFFFFF'
      }
    }
  };

  return (
    <ConfigProvider theme={theme}>
      <HeaderFT2
        isHideLeftIcon={[1].includes(step)}
        rightIems={
          [1].includes(step) ? (
            <ActionIcon icon={<X />} onClick={() => navigate('/refund-request')} />
          ) : action === 'view' && ['pending', 'rejected'].includes(statusRequest) ? (
            <Button size="large" style={{ width: '140px' }} onClick={onEditMode}>
              수정
            </Button>
          ) : (
            <></>
          )
        }
        title="신청서"
        onNavigate={onNavigate}
      />
      <Content
        style={{
          minHeight: 380,
          backgroundColor: '#fff',
          paddingTop: '24px',
          paddingBottom: '10px'
        }}>
        <div className="create-new-request">
          <Spin tip="Loading" spinning={loading}>
            <div className="px-3">
              <Row className="mx-2 mb-3">
                <Col span={24}>
                  <Select
                    value={country}
                    disabled={step === 2}
                    onChange={(value) => setCountry(value)}
                    style={{
                      width: '100%',
                      marginBottom: '10px',
                      border: '1px solid #D0D5DD',
                      borderRadius: '5px'
                    }}
                    suffixIcon={<ChevronDown />}
                    bordered={false}
                    size="large"
                    options={countries_options}
                  />
                </Col>
                {/* <Col span={24}>
                  <div className="d-flex justify-content-between w-100">
                    <Text>평균 환급 기간 : {COUNTRIES?.[country]?.refund_time || '1~2개월'}</Text>
                    <Text>
                      부가세 {(COUNTRIES?.[country]?.vat_rate || 0.2) * 100}% 중{' '}
                      <strong style={{ color: 'red' }}>{(COUNTRIES?.[country]?.refund_rate || 0.1) * 100}%</strong> 환급
                    </Text>
                  </div>
                </Col> */}
              </Row>
            </div>
            <Segmented
                options={[
                  { value: 'single', label: '환급 신청' },
                  { value: 'bulk', label: '대량 환급 신청(준비중)' }
                ]}
                block
                onChange={(value) => setFormType(value)}
                // disabled={action !== 'new' || step !== 1}
                disabled={true}
                size="large"
                style={{ borderRadius: 0 }}
              />
            {formType === 'bulk' && <BulkCreateRefundRequest />}
            {formType === 'single' && (
              <Form
                name="create-refund-request"
                layout="vertical"
                form={form}
                // onFinish={onSubmit}
                disabled={step === 2}
                initialValues={{
                  recipient: '',
                  recipient_en: '',
                  areaCode: 'korean',
                  phone: '',
                  tax_id: '',
                  address: '',
                  address_en: '',
                  postal_code: '',
                  coupon_code: '',
                  coupon_amount: 0,
                  collect_customs: false,
                  tax_payment: false,
                  // delivery_agency: '',
                  // tracking_number: '',
                  invoice_number: '',
                  // delivery_date: null,
                  memo: null,
                  fta_doc_issuance: false,
                  certificate_pictures: [],
                  invoices: [
                    {
                      discount: 0,
                      invoice_number_eu: null,
                      pictures: [],
                      products: [
                        {
                          product_url: '',
                          product_name: '',
                          product_origin: '',
                          product_option: '',
                          quantity: 1,
                          total_price: 0,
                          unit_price: 0,
                          unit_price_incl_vat: 0,
                          vat: 0,
                          est_refund_amount: 0,
                          total_price_incl_vat: 0,
                          memo: null
                        }
                      ]
                    }
                  ]
                }}>
                {step === 1 && (
                  <>
                    <RequestInfo
                      form={form}
                      country={country}
                      role={userInfo.role}
                      refundRate={userInfo?.refund_rate}
                      handleValidateInvoices={handleValidateInvoices}
                      handleValidateProducts={handleValidateProducts}
                      submittable={submittable}
                      handleCoupon={handleCoupon}
                    />
                    <Form.Item shouldUpdate>
                      {({ getFieldsValue }) => {
                        return (
                          <div className="fixed-bottom-container">
                            <Button
                              disabled={handleValidateForm(getFieldsValue())}
                              // disabled={!submittable}
                              onClick={handleToStep2}
                              // className="fixed-bottom-btn"
                              block
                              style={handleValidateForm(getFieldsValue()) ? {} : { backgroundColor: '#3431D6' }}
                              type="primary">
                              다음
                            </Button>
                          </div>
                        );
                      }}
                    </Form.Item>
                  </>
                )}
                {step === 2 && (
                  <>
                    <RequestInfo mode="view" form={form} role={userInfo.role} country={country} requestId={requestId} />
                    <Form.Item shouldUpdate>
                      {({ getFieldsValue }) => {
                        return (
                          <div className="fixed-bottom-container">
                            {(requestId && action === 'edit') || !requestId ? (
                              <Button
                                htmlType="submit"
                                style={{ backgroundColor: '#3431D6' }}
                                onClick={handleClickSubmitBtn}
                                // className="fixed-bottom-btn"
                                block
                                type="primary"
                                disabled={false}>
                                신청서 접수
                              </Button>
                            ) : (
                              <Button
                                htmlType="submit"
                                onClick={onDelete}
                                // className="fixed-bottom-btn"
                                block
                                type="primary"
                                disabled={statusRequest !== 'pending' && requestId}
                                danger>
                                삭제
                              </Button>
                            )}
                          </div>
                        );
                      }}
                    </Form.Item>
                  </>
                )}
              </Form>
            )}
          </Spin>
        </div>
      </Content>
    </ConfigProvider>
  );
};

export default CreateNewRefundRequest;
