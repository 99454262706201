import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { filter, flatMap, map } from 'lodash';
import { getStatusLabel, REFUND_REQUEST_STATUS } from '../../common/utilities/constants';
import {
  fetchRefundRequest,
  fetchRefundRequestStatus,
  fetchDefaultRecipient
} from '../../views/features/refundRequest/api';

export const fetchRefundRequestThunk = createAsyncThunk('ft/refundRequest/all', async (params, thunkApi) => {
  params['sort'] = '-created_at';
  return await fetchRefundRequest(params, thunkApi);
});
export const fetchRefundRequestStatusThunk = createAsyncThunk(
  'ft/refundRequest/status/all',
  async (params, thunkApi) => {
    return await fetchRefundRequestStatus(params, thunkApi);
  }
);
export const fetchDefaultRecipientThunk = createAsyncThunk(
  'ft/refundRequest/defaultRecipient/get',
  async (params, thunkApi) => {
    return await fetchDefaultRecipient(thunkApi);
  }
);

const initialState = {
  loading: false,
  isOpenSearchDrawer: false,
  totalPage: 0,
  totalResources: 0,
  page: 1,
  size: 50,
  refund_rate: parseFloat(process.env.REACT_APP_REFUND_RATE) || 0.083,
  resources: [],
  statusOptions: [],
  pending_count: 0,
  default_recipient: null,
  filters: {}
};

export const refundRequestSlice = createSlice({
  name: 'refundRequest',
  initialState,
  reducers: {
    openSearchDrawer: (state) => {
      state.isOpenSearchDrawer = true;
    },
    closeSearchDrawer: (state) => {
      state.isOpenSearchDrawer = false;
    },
    changeSize: (state, action) => {
      state.size = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    changeFilters: (state, action) => {
      state.filters = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRefundRequestThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRefundRequestThunk.fulfilled, (state, action) => {
        state.resources = action.payload.resources;
        state.totalPage = action.payload.total_pages;
        state.totalResources = action.payload.total_resources;
        state.loading = false;
      })
      .addCase(fetchRefundRequestThunk.rejected, (state, action) => {
        state.loading = false;
        state.resources = 0;
        state.totalPage = 0;
        state.totalResources = [];
      })
      .addCase(fetchDefaultRecipientThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDefaultRecipientThunk.fulfilled, (state, action) => {
        state.default_recipient = action.payload;
        state.loading = false;
      })
      .addCase(fetchDefaultRecipientThunk.rejected, (state, action) => {
        state.loading = false;
        state.default_recipient = null;
      })
      .addCase(fetchRefundRequestStatusThunk.fulfilled, (state, action) => {
        const status = action.payload || {};
        const newStatus = flatMap(status, (value, key) => {
          if (key === 'pending') {
            state.pending_count = value;
          }
          const matchItem = filter(REFUND_REQUEST_STATUS, ['value', key]);
          if (matchItem.length === 0 && key !== 'all') {
            return [];
          }
          return {
            value: key,
            label: getStatusLabel(key, REFUND_REQUEST_STATUS) + ' ' + `(${value})`
          };
        });
        state.statusOptions = newStatus;
      });
  }
});

export const { openSearchDrawer, closeSearchDrawer, changePage, changeSize, changeFilters } =
  refundRequestSlice.actions;

export default refundRequestSlice.reducer;
