import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { profile, userService } from '../../services/authService';
import { getCookie, removeCookie, setCookie } from '../../utility/utils';
import { fetchRefunds } from '../../views/pages/profile/api';

export const fetchRefundThunk = createAsyncThunk('ft/refund/all', async (params, thunkApi) => {
  return await fetchRefunds(params, thunkApi);
});

const initialState = {
  userInfo: {},
  getInfoLoading: false,
  loading: false,
  isLogin: getCookie('access_token') ? true : false,
  refundTotalPage: 0,
  totalRefundResources: 0,
  refundPage: 1,
  refundSize: 500,
  refundResources: [],
  refundLoading: false
};

export const fetchUserInfoThunk = createAsyncThunk('auth/me', async (data, thunkAPI) => {
  try {
    const userData = await profile();
    return userData;
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
});

export const loginThunk = createAsyncThunk('auth/login', async (body, thunkApi) => {
  try {
    const data = await userService.login(body);
    return data;
  } catch (error) {
    message.error(error?.response?.data?.message || 'Login failed');
    return thunkApi.rejectWithValue();
  }
});

export const logoutThunk = createAsyncThunk('auth/logout', async (data, thunkApi) => {
  try {
    const data = await userService.logout();
    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error?.response?.data?.message || 'Logout failed');
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
      setCookie('user', action.payload);
    },
    handleLogout: () => {
      removeCookie('user');
      state.userInfo = {};
    },
    changeRefundSize: (state, action) => {
      state.refundSize = action.payload;
    },
    changeRefundPage: (state, action) => {
      state.refundPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      setCookie('access_token', JSON.stringify(action.payload.accessToken));
      setCookie('refresh_token', JSON.stringify(action.payload.refreshToken));
      state.isLogin = true;
      state.loading = false;
    });
    builder.addCase(loginThunk.rejected, (state, action) => {
      state.loading = false;
      state.isLogin = false;
    });
    builder.addCase(logoutThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(logoutThunk.fulfilled, (state, action) => {
      removeCookie('access_token');
      removeCookie('refresh_token');
      state.isLogin = false;
      state.loading = false;
      removeCookie('user');
      state.userInfo = {};
    });
    builder.addCase(logoutThunk.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchUserInfoThunk.pending, (state, action) => {
      state.getInfoLoading = true;
    });
    builder.addCase(fetchUserInfoThunk.fulfilled, (state, action) => {
      state.userInfo = action.payload;
      state.getInfoLoading = false;
    });
    builder.addCase(fetchUserInfoThunk.rejected, (state, action) => {
      state.getInfoLoading = false;
    });
    builder.addCase(fetchRefundThunk.pending, (state, action) => {
      state.refundLoading = true;
    });
    builder.addCase(fetchRefundThunk.fulfilled, (state, action) => {
      state.refundResources = action.payload;
      state.totalRefundResources = action.payload?.total_resources;
      state.refundLoading = false;
    });
    builder.addCase(fetchRefundThunk.rejected, (state, action) => {
      state.refundLoading = false;
      state.refundTotalPage = 0;
      state.refundResources = [];
      state.totalRefundResources = 0;
    });
  }
});

export const { handleLogout, setUserInfo, changeRefundPage, changeRefundSize } = authSlice.actions;

export default authSlice.reducer;
