import Login from '../views/pages/login';
import NotFound from '../views/pages/not-found';
import ResetPasswordPage from '../views/pages/reset-password/ResetPasswordPage';
import SignUpPage from '../views/pages/sign-up/SignUpPage';
import SignInInactive from '../views/pages/sign-up/SignInInactive';

const PublicRoutes = [
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/sign-up',
    element: <SignUpPage />
  },
  {
    path: '/inactive',
    element: <SignInInactive />
  },
  {
    path: '/reset-password',
    element: <ResetPasswordPage />
  },
  {
    path: '/*',
    element: <NotFound />
  }
];
export default PublicRoutes;
