import { Navigate } from 'react-router-dom';
import HTVLayout from '../common/layout/HTVLayout';
import ProfilePage from '../views/pages/profile/ProfilePage';
import RefundRequestPage from '../views/pages/refundRequest/RefundRequestPage';
import CreateNewRefundRequest from '../views/pages/refundRequest/CreateNewRefundRequest';
import PrivateRoute from './PrivateRoute';
import OnboardingPage from '../views/pages/onboarding/OnboardingPage';
import RefundPage from '../views/pages/refund/RefundPage';
import RefundListPage from '../views/pages/refund/RefundListPage';
import RefundDestinationPage from '../views/pages/refundDestination/RefundDestinationPage';
import RefundRequestCompleted from '../views/pages/refundRequest/RefundRequestCompleted';
import ShippingCostPage from '../views/pages/shipping-cost/ShippingCostPage';
export { default as PrivateRoute } from './PrivateRoute';
export { default as PublicRoutes } from './PublicRoutes';

const AllPrivateRoutes = [
  {
    path: '/',
    index: true,
    element: <Navigate to="/refund-request" replace />
  },
  {
    path: 'profile',
    element: <ProfilePage />
  },
  {
    path: 'refund-request',
    element: <RefundRequestPage />
  },
  {
    path: 'create-refund-request',
    element: <CreateNewRefundRequest />
  },
  {
    path: 'refund-request/detail',
    element: <CreateNewRefundRequest action="view" />
  },
  {
    path: 'refund-request/edit',
    element: <CreateNewRefundRequest action="edit" />
  },
  {
    path: 'refund-request/completed',
    element: <RefundRequestCompleted />
  },
  {
    path: 'onboarding',
    element: <OnboardingPage />
  },
  {
    path: 'refund',
    element: <RefundPage />
  },
  {
    path: 'shipping-cost',
    element: <ShippingCostPage />
  },
  {
    path: 'refund/:type',
    element: <RefundListPage />
  },
  {
    path: 'refund-destination',
    element: <RefundDestinationPage />
  }
];

export const AllProtectedRoute = [
  {
    path: '/',
    element: (
      <PrivateRoute>
        <HTVLayout />
      </PrivateRoute>
    ),
    children: [...AllPrivateRoutes]
  }
];
