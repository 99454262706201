import React from 'react';
import { List, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { REFUND_TYPE } from './constants';
import { formatCurrency } from '../../../utility/utils';
import { ExternalLinkBlack } from '../../../assets/icons/Icons';
import ClickPreviewImagesLink from '../../../common/components/ClickPreviewImagesLink';
import { useNavigate } from 'react-router-dom';
const { Text, Link } = Typography;

const RefundListItem = ({ refund, currency, setMemo = null, setOpenMemo = null }) => {
  const navigate = useNavigate();
  const refundType = REFUND_TYPE[refund.type];

  let description;
  switch (refundType.value) {
    case REFUND_TYPE.added.value:
    case REFUND_TYPE.deducted.value:
      description = (
        <>
          {refund?.pictures?.length > 0 && (
            <ClickPreviewImagesLink
              title={
                <Link underline>
                  인보이스 {' '}
                  <ExternalLinkBlack />
                </Link>
              }
              images={refund?.pictures}
            />
          )}
          {refund?.memo && (
            <Link
              underline
              onClick={() => {
                setMemo(refund?.memo);
                setOpenMemo(true);
              }}>
              메모 {' '}
              <ExternalLinkBlack />
            </Link>
          )}
        </>
      );
      break;
  }

  description = (
    <Space>
      {refund?.refund_request?.request_ref && (
            <Link
              underline
              onClick={() => {
                navigate(`/refund-request/detail?id=${refund?.refund_request?.request_ref || refund?.refund_request_id}&return_to=refund`)
              }}>
                {refund?.refund_request?.request_ref}
                {' '}
              <ExternalLinkBlack />
            </Link>
          )}
      {description}
    </Space>
  );

  return (
    <List.Item key={refund.id}>
      <List.Item.Meta
        title={
          <Space direction="vertical" size={4}>
            <Text>{dayjs(refund.date).format('YYYY.MM.DD')}</Text>
            <Text strong>{refundType?.description}</Text>
          </Space>
        }
        description={description}
      />
      <Text strong style={{ color: refundType?.color, textAlign: 'end' }}>
        {refundType.sign}
        {formatCurrency(refund.amount, currency?.scale)}
        {currency.label}
        <br />
        {refundType?.display}
      </Text>
    </List.Item>
  );
};

export default RefundListItem;
