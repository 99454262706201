import { Button, Drawer, Input, message, Typography } from 'antd';
import React, { useState } from 'react';
import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { closeSendInquiry } from '../../redux/reducers/layoutSlice';
import { apiClientV2 } from '../../services/axios';
const { Text } = Typography;

const SendInquiry = () => {
  const dispatch = useDispatch();
  const { isOpenSendInquiry } = useSelector((state) => state.layout);
  const [value, setValue] = useState();

  const handleSendInquiry = async () => {
    try {
      const res = await apiClientV2.post('/message/inquiry', {
        content: value
      });
      if (res.status === 201) {
        message.success('Successfully');
        dispatch(closeSendInquiry());
      } else {
        message.error(res?.data?.message || 'Error');
      }
    } catch (error) {
      message.error(error?.response?.data?.message || 'Error');
    }
  };
  return (
    <Drawer
      title={false}
      placement={'bottom'}
      closable={false}
      onClose={() => dispatch(closeSendInquiry())}
      open={isOpenSendInquiry}
      rootClassName="optiosDrawer"
      contentWrapperStyle={{
        heigt: '200px',
        // maxHeight: '420px',
        overflow: 'scroll'
      }}>
      <div className="d-flex justify-content-between">
        <p
          style={{
            fontSize: '16px',
            color: '#101828',
            fontWeight: '700'
          }}>
          불편한 점이 있다면 알려주세요!
        </p>
        <X style={{ marginBottom: '20px', fontSize: '18px' }} onClick={() => dispatch(closeSendInquiry())} />
      </div>
      <div className="d-flex flex-column">
        <Text className='my-2'>이제 첫걸음을 내딛은 프리티고는 아직 부족한 부분이 많아요</Text>
        <Text className='mb-2'>사용하시면서 불편하거나 개선되었으면 하는 점이 있다면 자유롭게 알려주세요</Text>
        <Input.TextArea
          rows={4}
          placeholder={`자유롭게 작성해 주세요 \n운영진이 검토한 후 최대한 신속히 개선해 보겠습니다`}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button type="primary" block disabled={!value} size="large" className="mt-4" onClick={handleSendInquiry}>
          제출하기
        </Button>
      </div>
    </Drawer>
  );
};

export default SendInquiry;
