import { Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeBusinessIntroduce } from '../../redux/reducers/layoutSlice';
import PDFViewer from '../components/PdfViewer';
import './style.scss';

const BusinessIntroduce = () => {
  const dispatch = useDispatch();
  const { isOpenBusinessIntroduce } = useSelector((state) => state.layout);
  const [pdfFile, setPdfFile] = useState(process.env.PUBLIC_URL + '/introduce.pdf');

  return (
    <>
      <Modal
        centered
        open={isOpenBusinessIntroduce}
        width={false}
        bodyStyle={{ height: '100vh', overflow: 'scroll' }}
        footer={null}
        onCancel={() => dispatch(closeBusinessIntroduce())}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
        <PDFViewer file_view={pdfFile} />
      </Modal>
    </>
  );
};

export default BusinessIntroduce;
