import axios from 'axios';
import { getCookie } from '../utility/utils';
import { userService } from './authService';
import qs from 'qs';

const baseURL = `${process.env.REACT_APP_API_ENDPOINT}`;

export const instance = axios.create({ baseURL });

export const apiClientV2 = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: false,
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  }
});

apiClientV2.interceptors.request.use(
  (config) => {
    const token = JSON.parse(getCookie('access_token'));
    if (!config.headers['Authorization']) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClientV2.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const newAccessToken = await userService.refreshToken();
      if (newAccessToken) {
        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
      }
      return instancePrivate(prevRequest);
    }
    return Promise.reject(error);
  }
);

export default apiClientV2;
