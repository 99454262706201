import { Button, Tooltip } from "antd";

export const ButtonWithTooltip = ({ buttonTitle, tooltipContent, tooltipOpen, onClickOutside, buttonProps, tooltipProps }) => {
    return <Tooltip
        open={tooltipOpen}
        overlayStyle={{ maxWidth: '100%', width: '100%', padding: '0 24px' }}
        overlayInnerStyle={{
            padding: '16px',
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '24px',
            textAlign: 'left',
            backgroundColor: 'black',
        }}
        title={tooltipContent}
        {...tooltipProps}
    >
        <Button block {...buttonProps}>
            {buttonTitle}
        </Button>
    </Tooltip>
}