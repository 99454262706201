import { InputNumber } from 'antd';
import React from 'react';
import './style.scss';

const InputNumberCustom = ({ ...remain }) => {
  return (
    <InputNumber
      className="input-number-custom"
      {...remain}
    />
  );
};

export default InputNumberCustom;
