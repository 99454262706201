import { Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { generateThumbnail } from '../../utility/utils';

function PdfThumbnail({ file, ...props }) {
  const [thumbnailSrc, setThumbnailSrc] = useState(null);

  useEffect(() => {
    generateThumbnail(file).then((data_url) => {
      setThumbnailSrc(data_url);
    });
  }, [file]);

  return thumbnailSrc ? (
    <Image
      width={96}
      height={96}
      style={{ objectFit: 'cover', borderRadius: '5px' }}
      src={thumbnailSrc}
      preview={false}
      alt="PDF thumbnail"
      {...props}
    />
  ) : (
    <></>
  );
}

export default PdfThumbnail;
