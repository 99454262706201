import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List, Skeleton } from 'antd';
import { fetchRefunds } from '../../../common/api';
import { CURRENCIES } from '../../../common/utilities/constants';
import RefundListItem from './RefundListItem';

const RefundList = ({
  type = '',
  types,
  maxHeight = 500,
  setMemo = null,
  setOpenMemo = null,
  currencyId,
  ...props
}) => {
  const id = ['refund-list', Math.random().toString(36).substring(2)].join('-');
  const size = 1000;
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refunds, setRefunds] = useState([]);
  const [currency, setCurrency] = useState(CURRENCIES.KRW);

  const loadMore = useCallback(async () => {
    try {
      if (loading) return;
      setLoading(true);
      const nextPage = currencyId === currency.id ? page + 1 : 1;
      const res = await fetchRefunds({
        page: nextPage,
        size,
        currency_id: currencyId,
        ...(type ? { type } : { types })
      });
      const resources = nextPage === 1 ? res.resources : [...refunds, ...res.resources];
      setPage(nextPage);
      setHasMore(resources.length < res.total_resources);
      setRefunds(resources);
      setCurrency(CURRENCIES[res.currency.name]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    loadMore();
  }, [currencyId]);

  return (
    <div id={id} style={{ maxHeight: maxHeight, overflow: 'auto' }} {...props}>
      <InfiniteScroll
        dataLength={refunds.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<Skeleton />}
        scrollableTarget={id}>
        <List
          dataSource={refunds}
          renderItem={(refund) => (
            <RefundListItem refund={refund} currency={currency} setOpenMemo={setOpenMemo} setMemo={setMemo} />
          )}
        />
      </InfiniteScroll>
    </div>
  );
};

export default RefundList;
