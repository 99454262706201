import { Drawer } from 'antd';
import React from 'react';

const InformationDrawer = ({ title, content, ...props }) => {
  return (
    <Drawer
      title={title}
      placement="bottom"
      closable={true}
      contentWrapperStyle={{ height: 'auto' }}
      style={{ borderRadius: '12px 12px 0px 0px' }}
      getContainer={false}
      {...props}>
      <p>{content}</p>
    </Drawer>
  );
};

export default InformationDrawer;
