import { useState } from 'react';
import { Image, Modal } from 'antd';
import PDFViewer from './PdfViewer';
import { generateThumbnail } from '../../utility/utils';

const ClickPreviewImagesLink = ({ title, images, ...prop }) => {
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [pdfView, setPdfView] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [previewSrc, setPreviewSrc] = useState(null);
  return (
    <>
      <Image.PreviewGroup
        preview={{
          visible: isPreviewVisible,
          onVisibleChange: (visible, prevVisible) => setPreviewVisible(visible),
          imageRender: (originalNode, info) => {
            const isPdf = originalNode.props.src?.toLowerCase()?.endsWith('.pdf');
            if (isPdf) {
              generateThumbnail(originalNode.props.src).then((data_url) => {
                setPreviewSrc(data_url);
              });
            } else {
              setPreviewSrc(originalNode.props.src);
            }
            const newNode = {
              ...originalNode,
              props: {
                ...originalNode.props,
                src: previewSrc,
                onClick: () => {
                  if (isPdf) {
                    setPdfView(true);
                    setPdfFile(originalNode.props.src);
                  }
                }
              }
            };
            return newNode;
          }
        }}
        items={images?.map((img) => img.file_view)}
        >
      </Image.PreviewGroup>
      <a
        style={{
          textDecoration: 'underline'
          // color: '#297DCB'
        }}
        onClick={() => setPreviewVisible(!isPreviewVisible)}
        {...prop}>
        {title}
      </a>
      <Modal
        centered
        open={pdfView}
        width={false}
        bodyStyle={{ height: '100vh', overflow: 'scroll' }}
        footer={null}
        onCancel={() => {
          setPdfView(false);
        }}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
        <PDFViewer file_view={pdfFile} />
      </Modal>
    </>
  );
};

export default ClickPreviewImagesLink;
