import { Layout, Space } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { MenuBar } from '../../assets/icons/Icons';
import { openSideBar } from '../../redux/reducers/layoutSlice';
import './style.scss';
import ActionIcon from '../../common/components/ActionIcons';
const { Header } = Layout;

const HeaderFT = ({ title, rightIems }) => {
  const dispatch = useDispatch();

  return (
    <Header
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 50,
        width: '100%',
        backgroundColor: '#ffff',
        display: 'flex',
        padding: '0 15px',
        justifyContent: 'space-between',
        boxShadow: '0px 4px 4px 0px #00000040'
      }}>
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex">
          <div className="me-1">
            <ActionIcon icon={<MenuBar />} onClick={() => dispatch(openSideBar())} />
          </div>
          <p className="title">{title}</p>
        </div>
        <Space align="center" size="middle">
          {rightIems && <div>{rightIems}</div>}
        </Space>
      </div>
    </Header>
  );
};

export default HeaderFT;
