import { Button, Col, Drawer, Row, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import TransferForm from './TransferForm';
const { Title } = Typography;

const TransferDrawer = ({ onSubmitted, open, onClose, ...props }) => {
  return (
    <Drawer
      open={open}
      closeIcon={false}
      onClose={onClose}
      placement="bottom"
      contentWrapperStyle={{ height: 'auto' }}
      style={{ borderRadius: '12px 12px 0px 0px' }}
      {...props}>
      <Row justify="space-between">
        <Col>
          <Title level={5}>환급금 이체 신청</Title>
        </Col>
        <Col>
          <Button type="text" icon={<CloseOutlined />} onClick={onClose} />
        </Col>
      </Row>
      <TransferForm opened={open} onSubmitted={onSubmitted} />
    </Drawer>
  );
};
export default TransferDrawer;
