import React from 'react';
import { Space, Badge } from 'antd';
import { capitalize } from 'lodash';

const StatusTag = ({ status, opts }) => {
  const configArr = opts.filter((i) => i.value === status);
  const config = configArr.length > 0 ? configArr[0] : null;
  const maxDots = Array.from({ length: 6 }, (_, i) => i + 1);
  return (
    config ?
    <>
      <Space>
        {maxDots.map((num, idx) => {
            return <Badge key={idx} color={num <= config.dot ? config.dotColor : '#D0D5DD'} />
        })}
        <b style={{ color: `${config?.color}`, fontSize: '10px', backgroundColor: `${config.backgroundColor}`, padding: '5px 10px', borderRadius: '12px' }}>{config?.label}</b>
      </Space>
    </>
    : <Space>
    <b style={{ color: `#FFFFFF`, fontSize: '10px', backgroundColor: `#98A2B3`, padding: '5px 10px', borderRadius: '12px' }}>{capitalize(status?.replace('_', ' ') || '')}</b>
  </Space>
  );
};

export default StatusTag;
